import React, { useState } from 'react';

import { Button, Flex, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserXmark, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { doc, getFirestore, updateDoc } from '@firebase/firestore';

const { Text } = Typography;

export default function RejectedScreen() {
    const { assignedLab, rejectReason, uid } = useSelector(state => state.authentication.userDetails);

    const [isLoading, setIsLoading] = useState(false);

    const handleResubmit = async () => {
        try {
            setIsLoading(true);

            const db = getFirestore();

            await updateDoc(doc(db, 'users', uid), {
                active: true
            });

            setIsLoading(false);
        } catch(err) {
            
        }
    };

    return (
        <div className='mt-[50px]'>
            <Flex align='center' className='mb-[30px]'>
                <FontAwesomeIcon icon={faUserXmark} className='me-[20px] text-red-500' size='3x' /> 
                <Typography className='oswald font-semibold text-[42px]'>Application Rejected</Typography>
            </Flex>
            <Typography className='text-[#646464] oswald text-[42px] mb-[5vh]'>
                Your application to lab: {assignedLab} has been rejected.    
            </Typography>

            <Typography className='text-[#434343] oswald text-[32px] inline'>Remark:</Typography><Text code className='text-[#434343] oswald text-[32px]'>{rejectReason}</Text>
            <br />
            <Flex className='h-[40vh]' justify='end' align='start' vertical={true}>
                <Button disabled={isLoading} onClick={handleResubmit} className='mt-[50px] CSSuccess'>Resubmit Application</Button>
            </Flex>
        </div>
    );
}