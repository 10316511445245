import React, { useState, useEffect } from 'react';

import { getFirestore, getDoc, doc } from '@firebase/firestore';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function HIRARCStatsPieChart() {
    const { assignedLab } = useSelector(state => state.authentication.userDetails);

    const [HIRARCStats, setHIRARCStats] = useState({
        totalRisks: 0,
        highRisk: 0,
        medRisk: 0,
        lowRisk: 0,
        Explosive: 0, 
        Flammable: 0, 
        Oxidizing: 0, 
        Corrosive: 0, 
        Toxic: 0, 
        Harmful: 0, 
        'Compressed Gas': 0, 
        'Health Hazard': 0, 
        'Environmental Hazard': 0
    });

    const fetchHIRARCStats = async () => {
        try {
            const db = getFirestore();

            const stats = await getDoc(doc(db, 'HIRARC', assignedLab));

            setHIRARCStats(stats.data());
        } catch (err) {

        }
    };

    useEffect(_ => {
        Promise.all([fetchHIRARCStats(assignedLab)]);
    }, [assignedLab]);

    return (
        <div className='w-1/3 CSBorder CSBoxBorder !rounded-[5px]'>
            <Pie data={
                {
                    labels: ['High', 'Medium', 'Low'],
                    datasets: [
                        {
                            label: 'Risks',
                            data: [HIRARCStats.highRisk, HIRARCStats.medRisk, HIRARCStats.lowRisk],
                            backgroundColor: ['#FF7964', '#FFD966', '#8AE08A']
                        }
                    ]
                }
            } />
        </div>
    );
}