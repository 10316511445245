import React, { useState } from 'react';

import { Button, Space, Tooltip, Typography, Upload } from 'antd';
import { useSelector } from 'react-redux';
import { getStorage, ref, uploadBytes } from '@firebase/storage';
import { doc, getFirestore, updateDoc } from '@firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faStopwatch, faUpload } from '@fortawesome/free-solid-svg-icons';
import useNotification from 'antd/es/notification/useNotification';

import QuickAnalysisModal from './QuickAnalysisModal';

export default function ChemicalModalResult(props) {
    // Values collected from props and state - won't change
    const { chemical, fetchResults } = props;
    const { assignedLab, accessLevel } = useSelector(state => state.authentication.userDetails);

    // If the user wants to upload an SDS from the modal
    const [isUploading, setIsUploading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    // For the quick modal
    const [notificationAPI, context] = useNotification();

    const uploadFile = async (file, uid) => {
        try {
            setIsUploading(true);
            const storage = getStorage();
            const db = getFirestore();

            await uploadBytes(ref(storage, `/chemicals/${assignedLab}/SDS/${uid}.pdf`), file);
            
            // Once it has been uploaded, update the original doc to state it is available
            await updateDoc(doc(db, 'Chemicals', assignedLab, 'chemicals', uid), {
                hasSDS: true
            });

            setIsUploading(false);

            fetchResults();
        } catch (err) {

        }
    }

    return (
        <section className='py-3 grid grid-cols-3'>
            {context}
            <QuickAnalysisModal cas={chemical.id}  isOpen={isModalOpen} setIsOpen={setIsModalOpen} notificationAPI={notificationAPI} initialValues={chemical.data()} />
            <Typography>{chemical.id}</Typography>
            <Typography>{getStatusMessage(chemical.data())}</Typography>
            <Space className='justify-end' >
                {/* To set the quick analysis values */}
                {accessLevel > 1 && <Tooltip title='Set Quick Analysis Values'>
                    <Button disabled={isUploading} onClick={() => setIsModalOpen(true)}> <FontAwesomeIcon icon={faStopwatch} color={getQuickValuesColor(chemical.data())} /> </Button>
                </Tooltip>}
                {/* Upload button */}
                <Tooltip title='Upload SDS'>
                    <Upload accept='.pdf' maxCount={1} showUploadList={false} beforeUpload={file => uploadFile(file, chemical.id)}>
                        <Button disabled={isUploading}> <FontAwesomeIcon icon={isUploading ? faSpinner : faUpload} spinPulse={isUploading} /> </Button>
                    </Upload>
                </Tooltip>
            </Space>
        </section>
    );
}


function getStatusMessage(props) {

    const {quantity, soilPersist, stealable, volatile, waterPersist, hasSDS} = props;

    // Check the SDS first
    if(!hasSDS) {
        return 'Missing SDS!';
    }
    // Then check the special values
    else if (quantity === null || soilPersist === null || stealable === null || volatile === null || waterPersist === null) {
        return 'Missing Special Values!';
    
    // If all set, return the OK
    } else {
        return 'Quick Analysis Ready!';
    }

}

function getQuickValuesColor(props) {

    const {quantity, soilPersist, stealable, volatile, waterPersist} = props;

    // If all of them have a value, return green - done
    if (quantity !== null && soilPersist !== null && stealable !== null && volatile !== null && waterPersist !== null) {
        return '#42CA4E';

    // If some but not all have value, return yellow - pending
    } else if (quantity !== null || soilPersist !== null || stealable !== null || volatile !== null || waterPersist !== null) {
        return '#FFD21F';
    
    // If none of them have a value, return red - none
    } else {
        return '#FF4545';
    }

}