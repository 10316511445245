import { React, useState } from "react";
import { Flex, Layout } from "antd";

import CSHeader from "./CSHeader";
import CSCarousel from "./CSCarousel";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";

const { Header, Content } = Layout;

function Login (props) {
    
    const { listeners, setListeners } = props;

    const [isLogin, setIsLogin] = useState(true);

    return (
        <Layout style={{backgroundColor: 'transparent', margin: '30px 40px'}}>

            {/* Header of the page */}
            <Header  style={{backgroundColor: 'transparent', height: '15vh'}}>
                <CSHeader />
            </Header>

            {/* Content of the page */}
            <Content>
                <Flex align="center" justify="space-between">
                {/* Carousel */}
                <CSCarousel />
                <hr style={{rotate: '90deg', width: '25vw', borderWidth: 1}} />
                {/* Login Form */}
                { isLogin ? 
                    <LoginForm setLogin={() => setIsLogin(false)} listeners={listeners} setListeners={setListeners}/> : 
                    <RegisterForm setLogin={() => setIsLogin(true)} listeners={listeners} setListeners={setListeners}/> 
                }
                </Flex>
            </Content>

        </Layout>
    )
}

export default Login;