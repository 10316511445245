import React, { useState } from 'react';

import { Typography, Form, Input, Slider, Button, Tooltip, Flex, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';

import RiskChart from './RiskChart';
import EffectMenu from './EffectMenu';

function createMatrix (sev, like) {
    let newMatrix = new Array(5).fill(new Array(5).fill(null));

    newMatrix[like-1][sev-1] = 'X';

    return newMatrix;
}

export default function HIRARCForm (props) {
    const [riskMatrix, setRiskMatrix] = useState(createMatrix(1, 1));
    const [items, setItems] = useState(props.update !== null ? props.items[props.update].effects : ['New Item']);
    const [form] = useForm();

    // The GHS category that will dictate the Hazard Category
    const GHSCategories = ['Explosive', 'Flammable', 'Oxidizing', 'Compressed Gas', 'Corrosive', 'Toxic', 'Harmful', 'Health Hazard', 'Environmental Hazard'];
    // Taken from lecture notes provided
    const ControlsList = ['Elimination', 'Substitution', 'Engineering Controls', 'Administrative Controls', 'PPE'];

    return (
        <article>
            <Typography className='oswald text-[32px] ps-[6px] font-bold'>Add Risk</Typography>
        
            <section className='CSBoxShadow w-[83vw] min-h-[40vh] rounded-[10px] border-black border-[1px] ps-[30px] pe-[40px] py-[30px] mb-[50px]'>
                <Form disabled={props.isView} form={form} onFinish={(vals) => {
                    document.getElementById('Main').scrollTo({top: 0, left: 0, behavior: 'smooth'});
                    if (props.update === null) {
                        props.setItems([...props.items, {...vals, effects: items}]);
                    } else {
                        const newItems = props.items;
                        newItems[props.update] = {...vals, effects: items};
                        props.setItems(newItems);
                    }
                    props.setUpdate(null);
                    props.setEdit(false);
                }}
                    initialValues={props.update === null ? null : props.items[props.update]}
                >
                    {/* First Section */}
                    <Typography className='oswald font-bold text-[24px] mb-[40px]'>Step 1: Hazard Identification</Typography>
                    <div className='flex'>

                        {/* Left Side */}
                        <div className='flex flex-col w-1/2 pe-[50px]'>
                            <Typography className='CSFormLabel'>Work Activity</Typography>
                            <Form.Item
                                rules={[{required: true, message: 'Please Enter The Work Activity!'}]}
                                name='activity'
                            >
                                <Input className='CSFormInput'/>
                            </Form.Item>

                            <Typography className='CSFormLabel'>Hazard Category</Typography>
                            <Form.Item
                                rules={[{required: true, message: 'Please Select A Category!'}]}
                                name='hazard'
                            >
                                <Select className='CSFormInput border-[#979797] !rounded-[10px] border-[1px]' size='large'>
                                    {GHSCategories.map((cat, i) => <Select.Option value={cat}>{cat}</Select.Option>)}
                                </Select>
                            </Form.Item>
                        </div>
                        
                        {/* Right Side */}
                        <div className='w-1/2 pe-[50px]'>
                            <Typography className='CSFormLabel'>Effects</Typography>
                            <EffectMenu isView={props.isView} items={items} setItems={setItems} />
                        </div>
                    </div>
                    <hr className='border-[1px] w-full border-[#979797] mb-[30px]' />
                    
                    {/* Second Section */}
                    <Typography className='oswald font-bold text-[24px] mb-[40px]'>Step 2: Risk Assessment</Typography>
                    <div className='flex flex-row'>

                        {/* Left Side Controls */}
                        <div className='w-1/3 pe-[50px]'>
                            {/* Existing Controls */}
                            <Typography className='CSFormLabel'>Existing Controls</Typography>
                            <Form.Item
                                rules={[{required: true, message: 'Please Enter Existing Control!'}]}
                                name='exControl'
                            >
                                <Select className='CSFormInput !rounded-[10px] border-[1px]' size='large'>
                                    {ControlsList.map(con => <Select.Option value={con}>{con}</Select.Option>)}
                                </Select>
                            </Form.Item>

                            {/* Comments on Existing Controls */}
                            <Typography className='CSFormLabel'>Comments</Typography>
                            <Form.Item
                                initialValue={''}
                                name='exControlComments'
                            >
                                <Input className='CSFormInput' />
                            </Form.Item>

                            {/* Likelihood Slider */}
                            <Typography className='CSFormLabel inline-block me-3'>(L) Likelihood</Typography>
                            <Tooltip title={
                                <div>Likelihood of an event occurring?
                                    <br />1- Inconceivable
                                    <br />2- Remote
                                    <br />3- Conceivable
                                    <br />4- Possible
                                    <br />5- Most likely
                                </div>
                            }>
                                <FontAwesomeIcon icon={faCircleQuestion} className='inline accent' />
                            </Tooltip>
                            <Form.Item name="likelihood" initialValue={1} rules={[{required: true, message: 'Please Enter Likelihood Rating!'}]}>
                                <Slider
                                    min={1}
                                    max={5}
                                    step={1}
                                    marks={{
                                        1: '1',
                                        2: '2',
                                        3: '3',
                                        4: '4',
                                        5: '5',
                                    }}
                                    onChange={(val) => setRiskMatrix(createMatrix(form.getFieldValue('severity'), val))}
                                />
                            </Form.Item>

                            {/* Severity Input */}
                            <Typography className='CSFormLabel inline-block me-3'>(S) Severity</Typography>
                            <Tooltip title={
                                <div>How damaging is the event if it were to occur?
                                    <br />1- Neglibile
                                    <br />2- Minor
                                    <br />3- Serious
                                    <br />4- Fatal
                                    <br />5- Catastrophic
                                </div>
                            }>
                                <FontAwesomeIcon icon={faCircleQuestion} className='inline accent' />
                            </Tooltip>
                            <Form.Item name='severity' initialValue={1} rules={[{required: true, message: 'Please Enter Severity Rating!'}]} >
                                <Slider
                                    min={1}
                                    max={5}
                                    step={1}
                                    marks={{
                                        1: '1',
                                        2: '2',
                                        3: '3',
                                        4: '4',
                                        5: '5',
                                    }}
                                    onChange={(val) => setRiskMatrix(createMatrix(val, form.getFieldValue('likelihood')))}
                                />
                            </Form.Item>
                        </div>

                        {/* Right Side Chart */}
                        <RiskChart riskMatrix={riskMatrix} likelihood={form.getFieldValue('likelihood')} severity={form.getFieldValue('severity')} />
                    </div>
                    <hr className='border-[1px] w-full border-[#979797] mb-[30px]' />

                    {/* Third Section */}
                    <Typography className='oswald font-bold text-[24px] mb-[40px]'>Step 3: Risk Control</Typography>
                    <div className='flex flex-row'>
                        <div className='w-1/2 pe-[50px]'>
                            {/* Recommended Control Input */}
                            <Typography className='CSFormLabel'>Recommended Control</Typography>
                            <Form.Item rules={[{required: true, message: 'Please Enter Recommended Control!'}]} name='reqControl'>
                                <Select className='CSFormInput !rounded-[10px] border-[1px]' size='large'>
                                    {ControlsList.map(con => <Select.Option value={con}>{con}</Select.Option>)}
                                </Select>
                            </Form.Item>

                            {/* Comments on Existing Controls */}
                            <Typography className='CSFormLabel'>Comments</Typography>
                            <Form.Item
                                initialValue={''}
                                name='reqControlComments'
                            >
                                <Input className='CSFormInput' />
                            </Form.Item>
                        </div>

                        <div className='w-1/2'>
                            {/* Responsible Officer Input */}
                            <Typography className='CSFormLabel'>Responsible Officer</Typography>
                            <Form.Item name='officer' rules={[{required: true, message: 'Please Enter Responsible Officer!'}]}>
                                <Input className='CSFormInput'/>
                            </Form.Item>
                        </div>

                    </div>
                    <hr className='border-[1px] w-full border-[#979797] mb-[30px]' />
                    
                    <Flex justify='flex-end' gap={15}>
                    
                        {/* Cancel Button */}
                        <Button type='primary' disabled={false} danger className='w-[125px] h-[42px] rounded-[15px] roboto font-bold text-[24px]' 
                        onClick={() => {
                            document.getElementById('Main').scrollTo({top: 0, left: 0, behavior: 'smooth'});
                            props.setUpdate(null);
                            props.setEdit(false);
                            }}>Cancel</Button>

                        {/* Save Button */}
                        {!props.isView && <Button className='w-[125px] h-[42px] rounded-[15px] bg-[#42CA4E] text-white roboto font-bold text-[24px] hover:!bg-[#68cf70] hover:!text-white hover:!border-transparent' htmlType='submit'>Save</Button>}
                    
                    </Flex>
                </Form>
            </section>
        </article>
    );
}