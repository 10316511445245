const firebaseConfig = {
    apiKey: "AIzaSyDLvsGK7U5OUjXVRhLQPWtY6N7BgcQVmeI",
    authDomain: "chemras-d548c.firebaseapp.com",
    projectId: "chemras-d548c",
    storageBucket: "chemras-d548c.appspot.com",
    messagingSenderId: "1014459688925",
    appId: "1:1014459688925:web:052cc66713d50ea8117834",
    measurementId: "G-QJTMV3ZX2H"
  };

export { firebaseConfig };