import React, { useEffect } from 'react';

import { Form, Input, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import anime from 'animejs';

export default function AuthRegister(props) {
    // Placing the animation here
    useEffect(()=> {
        anime({
            targets: '#registerForm > div:nth-child(4)',
            opacity: [0, 1],
            duration: 3000
        })
    }, []);
    
    return (
        <Form
            id='registerForm'
            style={{
                width: '40vw',
                minWidth: '400px',
                padding: 30,
                // height: '60vh'
            }}
            onFinish={() => {
                props.setDetails(props.signUpForm.getFieldsValue());
                props.setCurrentPage(props.currentPage+1);
            }}
            form={props.signUpForm}
        >

            {/* Email Field */}
            <Form.Item
                name='email'
                rules={[
                    {
                        required: true,
                        message: 'Please enter an email address!'
                    },
                    {
                        validator: (_, val) => {
                            if(val === undefined || val ==="" || /.{1,}@(student\.)?usm\.my$/gm.test(val)) {
                                return Promise.resolve();
                            } else {
                                return Promise.reject();
                            }
                        },
                        message: 'Please enter a valid USM Email'
                    }
                ]}
            >
                <Input 
                    type="email" 
                    placeholder="Email" 
                    prefix={<FontAwesomeIcon icon={faUser} className='me-[12px] accent' />} 
                    className="CSInput oswald font-light text-[20px]"
                />
            </Form.Item>
            
            {/* Password Field */}
            <Form.Item
                name='password'
                rules={[
                    {
                        required: true,
                        message: 'Please enter a password!'
                    },
                    {
                        validator: (_, val) => {
                            if (val === undefined || val === "" || /^(?=.*\d)(?=.*[a-z])(?=.*[!-/:-@[-`{-~])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm.test(val)) {
                                return Promise.resolve();
                            } else {
                                return Promise.reject();
                            }
                        },
                        message: 'Password should consist of a mixture of upercase letters, lowercase letters, numbers, and special characters and should be at least 8 characters long!'
                    }
                ]}
            >
                <Input 
                    type="password" 
                    placeholder="Password" 
                    prefix={<FontAwesomeIcon icon={faLock} className='me-[12px] accent' />} 
                    className="CSInput oswald font-light text-[20px]"
                />
            </Form.Item>
            
            {/* Password Validation */}
            <Form.Item
                id='passwordConfirm'
                name='passwordConfirm'
                rules={[
                    {
                        required: true,
                        message: 'Please re-enter your password!'
                    },
                    {
                        validator: (_, val) => {
                            if(val !== props.signUpForm.getFieldsValue().password) {
                                return Promise.reject();
                            } else {
                                return Promise.resolve();
                            }
                        },
                        message: 'Passwords do not match!'
                    }
                ]}
            >
                <Input 
                    type="password" 
                    placeholder="Re-enter Password" 
                    prefix={<FontAwesomeIcon icon={faLock} className='me-[12px] accent' />}
                    className="CSInput oswald font-light text-[20px]"
                />
            </Form.Item>

            <Form.Item>
                <Button htmlType="submit" block className="CSSuccess mt-[48px]"> Next </Button>
            </Form.Item>
        </Form>
    );
}