import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Space } from 'antd';
import React from 'react';

export default function PagingCounter (props) {
    // Get props from the parent widget
    const {currentPage, totalPageNum= parseInt(props.totalPage), isLoading, moveBack, moveNext} = props;

    return (
        <Space size='middle' className='mt-5'>
            {/* Move back button */}
            {currentPage !== 1 && <FontAwesomeIcon icon={faArrowLeft} onClick={isLoading ? null : moveBack} />}
            
            {/* Initial Page (always there) */}
            {currentPage !== 1 && <Avatar size='small' className='text-black bg-transparent'>1</Avatar>}
            
            {/* Current page */}
            <Avatar className='text-black font-bold bg-yellow-300'>{currentPage}</Avatar>
            
            {/* Final page */}
            {currentPage !== totalPageNum && totalPageNum !== 0 && <Avatar size='small' className='text-black bg-transparent'>{totalPageNum}</Avatar>}
            
            {/* Move next button */}
            { currentPage !== totalPageNum && totalPageNum !== 0 && <FontAwesomeIcon icon={faArrowRight} onClick={isLoading ? null : moveNext} />}
        </Space>
    );
}