import React, { useState } from 'react';

import { Modal, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';

export default function RejectUserModal(props) {
    const { isOpen, setIsOpen, onRejectUser } = props;
    
    const [rejectReason, setRejectReason] = useState('');

    return (
        <Modal 
            open={isOpen} 
            closeIcon={false} 
            onCancel={() => {
                setIsOpen(false);
                setRejectReason('');
            }} 
            okType='default'
            okText='Reject User'
            cancelButtonProps={{danger: true, type: 'primary'}}
            onOk={() => {
                onRejectUser(rejectReason);
                setIsOpen(false);
                setRejectReason('');
            }}
        >
            <Typography className='oswald text-[24px] font-bold mb-2'>Reject User</Typography>

            <Typography className=''>Please enter the reason for rejecting the user:</Typography>
            
            <TextArea style={{resize: 'none', height: 100}} allowClear value={rejectReason} onChange={e => setRejectReason(e.target.value)} type='text' maxLength={100} showCount />
            <Typography className='text-[12px] font-light'>*Note - This message will be shown to the rejected user.</Typography>
        </Modal>
    );
}