import React from 'react';

import { Typography, Flex } from 'antd';

export default function RiskChart (props) {
    return (
        <div className='w-2/3'>
            <Typography className='CSFormLabel'>(R) Relative Risk</Typography>
            <Flex className='h-full'>
                <Flex vertical justify='space-between' align='center' className='h-[87%] w-8'>
                    <Typography>5</Typography>
                    <Typography className='rotate-[270deg] whitespace-nowrap'>Likelihood</Typography>
                    <Typography>0</Typography>
                </Flex>
                <Flex vertical={true} className='w-full'>
                    <section className='grid grid-cols-5 grid-rows-5 w-full h-4/5 rounded-[10px] w-full overflow-clip'>
                        {props.riskMatrix.map((el, like) => 
                            el.map((el2, sev) => 
                                <span
                                className={`border-[#979797] border-[1px] text-white ${(like+1) * (sev+1) >= 15 ? 'bg-[#FF4545]' : (like+1) * (sev+1) >= 5 || sev+1 === 4 || like+1 === 4? 'bg-[#FFD966]' : 'bg-[#85E0A3]'} font-white font-bold flex justify-center`}>
                                 {like === props.likelihood-1 && sev === props.severity-1 ? <span className='h-fit self-center text-[24px]'>x</span> : null}</span>
                                )
                            ).reverse()}
                    </section>
                    <Flex justify='flex-end'>
                        <div className="grow"></div>
                        <Typography>Severity</Typography>
                        <div className="grow"></div>
                        <Typography>5</Typography>
                    </Flex>
                </Flex>
            </Flex>
        </div>
    );
}