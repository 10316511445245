import React from 'react';

import { Flex, Typography } from 'antd';

import StatisticsTile from './StatisticsTile';
import HIRARCStatsPieChart from './HIRARCStatsChart';
import HIRARCStatsBarChart from './HIRARCStatsBarChart';
import ChemSAMHistoryTable from './ChemSAMHistoryTable';
import HIRARCHistoryTable from './HIRARCHistoryTable';
import LabSelector from '../LabSelector';


export default function ActiveScreen() {

    return (
        <div>
            <Flex justify='space-between' align='baseline'>
                <Typography className='oswald text-[48px] font-bold mb-[37px]'>Dashboard</Typography>
                
                <LabSelector />
                
            </Flex>

            <article>
                <Typography className='text-[32px] font-semibold oswald'>Statistics</Typography>
                <StatisticsTile />


                <section className='mb-[40px]'>
                    <Flex gap={15}>
                        {/* Risk distribution chart */}
                        <HIRARCStatsPieChart />

                        {/* The GHS Category distribution chart */}
                        <HIRARCStatsBarChart />
                    </Flex>
                </section>

                <section className='mb-[30px]'>
                    <Typography className='font-bold text-[32px] mb-[25px]'>Recent Analyses</Typography>
                    <ChemSAMHistoryTable />
                </section>

                <section className='mb-[30px]'>
                    <HIRARCHistoryTable />
                </section>
            </article>
        </div>
    );
}