import React, { useState, useEffect } from 'react';

import { Timestamp, getFirestore, getDocs, query, collection, where, updateDoc, doc } from '@firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faUserAltSlash } from '@fortawesome/free-solid-svg-icons';
import { Typography, Button, Flex, Avatar, Space } from 'antd';
import { useSelector } from 'react-redux'; 
import { getDownloadURL, getStorage, ref } from '@firebase/storage';

import RejectUserModal from './RejectUserModal';
import AcceptUserModal from './AcceptUserModal';
import EmptyTableMessage from '../EmptyTableMessage';

export default function PendingUserTable (props) {
    const { assignedLab, accessLevel } = useSelector(state => state.authentication.userDetails);

    // For rejecting/accepting user modals
    const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
    const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);

    const [selectedUID, setSelectedUID] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [pendingUsers, setPendingUsers] = useState([{
        email: <FontAwesomeIcon icon={faSpinner} spinPulse />,
        matric: 12345,
        timeStamp: Timestamp.now()
    }]);

    const fetchPendingUsers = async () => {
        try {
            const db = getFirestore();
            const storage = getStorage();

            const pendingUserSnap = await getDocs(query(collection(db, 'users'), where('verified', '==', false), where('assignedLab', '==', assignedLab), where('accessLevel', '==', 1), where('active', '==', true)));

            // Fetch the profile picture
            const userWithPfp = await Promise.all(pendingUserSnap.docs.map(async doc => {
                return {
                    ...doc.data(),
                    id: doc.id,
                    pfp: await getDownloadURL(ref(storage, `/users/${assignedLab}/${doc.id}/pfp.png`))
                }
            }));

            // Wait for everything to finish
            setPendingUsers(userWithPfp);

        } catch (err) {
            // TODO: Implement something here
        }
    };

    const handleAccept = async (uid, accessLevel = 1) => {
        try {
            setIsLoading(true);

            const db = getFirestore();

            await updateDoc(doc(db, 'users', uid), {
                verified: true,
                verifiedDate: Timestamp.now(),
                accessLevel: accessLevel
            });
        } catch (err) {
            // TODO: Something here
        } finally {
            await fetchPendingUsers();
            props.setTriggerStaffRender(!props.triggerStaffRender);
            setIsLoading(false);
        }
    };

    const handleReject = async (rejectMessage) => {
        try {
            setIsLoading(true);

            const db = getFirestore();

            await updateDoc(doc(db, 'users', selectedUID), {
                rejectReason: rejectMessage,
                active: false
            });

            setSelectedUID(null);
        } catch (err) {
            // TODO: Something here
        } finally {
            await fetchPendingUsers();
            setIsLoading(false);
        }
    };

    useEffect(_ => {
        setIsLoading(true);
        Promise.all([fetchPendingUsers()]).then(_ => {
            setIsLoading(false);
        });
    }, [assignedLab]);

    const pendingHeaders = ['Name', 'Matric No.', 'Date', 'Actions'];

    return (
        <section className='mb-[30px]'>
            <RejectUserModal isOpen={isRejectModalOpen} setIsOpen={setIsRejectModalOpen} onRejectUser={handleReject} />
            <AcceptUserModal isOpen={isAcceptModalOpen} setIsOpen={setIsAcceptModalOpen} onAcceptUser={handleAccept} selectedUID={selectedUID} />
            <Typography className='oswald font-bold text-[28px] mb-[10px] ps-[5px]'>Pending</Typography>
            <div className='CSBoxShadow CSBorder !rounded-[5px] pb-2'>
                <div className="CSPendingGrid">
                    {pendingHeaders.map(header => <Typography className='oswald text-[#646464] font-bold text-[24px]'>{header}</Typography>)}
                </div>
                <hr />

                <div className="max-h-[250px] overflow-y-scroll">
                    {pendingUsers.length === 0 ? <EmptyTableMessage message='No pending users' icon={faUserAltSlash} /> : pendingUsers.map(user => (
                        <div className="CSPendingGridContent px-1 py-2">
                            <Space size='middle'>
                                <Avatar shape='square' size='large' src={user.pfp} />
                                <Flex vertical={true}>
                                    <Typography className='roboto text-[18px]'>{isLoading ? <FontAwesomeIcon icon={faSpinner} spinPulse /> : user.name}</Typography>
                                    <Typography className='roboto text-[12px] font-light'>{isLoading ? <FontAwesomeIcon icon={faSpinner} spinPulse /> : user.email}</Typography>
                                </Flex>
                            </Space>
                            <Typography className='roboto text-[16px]'>{isLoading ? <FontAwesomeIcon icon={faSpinner} spinPulse /> : user.matric}</Typography>
                            <Typography className='roboto text-[16px]'>{isLoading ? <FontAwesomeIcon icon={faSpinner} spinPulse /> : user.timeStamp.toDate().toLocaleString()}</Typography>
                            <Flex gap={15}>
                                <Button type='primary' className='h-[30px] bg-[#42CA4E] roboto font-bold hover:!bg-[#68cf70]' onClick={_ => {
                                    setSelectedUID(user.id);
                                    accessLevel > 2 ? 
                                        setIsAcceptModalOpen(true) 
                                        : 
                                        handleAccept(user.id);
                                    }}>Approve</Button>
                                <Button danger type='primary' className='roboto font-bold' onClick={_ => {
                                    setIsRejectModalOpen(true);
                                    setSelectedUID(user.id);
                                }}>Reject</Button>
                            </Flex>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}