import React, { useState } from 'react';

import { Button, Flex, Form, Input, Modal, Select, Typography, Upload } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { doc, getDoc, getFirestore, setDoc } from '@firebase/firestore';
import { getStorage, ref, uploadBytes } from '@firebase/storage';
import { useSelector } from 'react-redux';

export default function ChemicalModal (props) {
    const { assignedLab } = useSelector(state => state.authentication.userDetails);
    const { isOpen, setIsOpen, fetchToPage, notificationAPI } = props;

    const [chemicalForm] = Form.useForm();
    const [isUploading, setIsUploading] = useState(false); 

    const handleSubmit = async (vals) => {
        setIsUploading(true);

        const { cas, name, state, hasSDS, quantity, soilPersist, stealable, volatile, waterPersist } = vals;
        const SDSAttached = hasSDS ? hasSDS.fileList.length > 0 : false;

        try {
            const db = getFirestore();
            const storage = getStorage();

            // Upload the document first
            const chemDoc = doc(db, 'Chemicals', assignedLab, 'chemicals', cas);
            
            if((await getDoc(chemDoc)).exists()) {
                throw(new Error('chemExists'));
            }

            await setDoc(chemDoc, {
                state: state,
                name: name,
                hasSDS: SDSAttached,
                quantity: quantity,
                soilPersist: soilPersist,
                stealable: stealable,
                volatile: volatile,
                waterPersist: waterPersist
            });

            // Upload the SDS if attached
            if(hasSDS) {
                await uploadBytes(ref(storage, `/chemicals/${assignedLab}/SDS/${cas}.pdf`), hasSDS.file);
            }

            setIsUploading(false);
            setIsOpen(false);

            notificationAPI.success({
                message: 'Chemical Added Successfully!'
            });

            chemicalForm.resetFields();

            fetchToPage();

        } catch (err) {
            notificationAPI.error({
                message: err.message === 'chemExists' ? 'Chemical Exists!' : 'Failed to add chemical!',
                description: err.message === 'chemExists' ? 'The chemical you are trying to add is already part of this lab.' : 'An error occurred while attempting to add the chemical.'
            });
        } finally {
            setIsUploading(false);
        }
    }

    return (
        <Modal open={isOpen} footer={null} closeIcon={null}>
            <Form form={chemicalForm} onFinish={handleSubmit} disabled={isUploading}>
                <Typography className='oswald text-[24px] font-bold mb-2'>Add Chemical</Typography>
                
                <Typography className='CSFormLabel mb-1'>CAS Number</Typography>
                <Form.Item
                    name='cas'
                    rules={[
                        {required: true, message: 'Please enter the CAS number of the chemical!'},
                        {
                            validator: (_, val) => {
                                if(!/^\d*-\d*-\d*$/g.test(val)) {
                                    return Promise.reject();
                                } else {
                                    return Promise.resolve();
                                }
                            },
                            message: 'Invalid CAS Format!'
                        }
                    ]}
                >
                    <Input className='CSFormInput' />
                </Form.Item>

                <Typography className='CSFormLabel mb-1'>Chemical Name</Typography>
                <Form.Item
                    name='name'
                    rules={[{
                        required: true,
                    }]}
                >
                    <Input className='CSFormInput' />
                </Form.Item>
                
                <Typography className='CSFormLabel mb-1'>State</Typography>
                <Form.Item
                    name='state'
                    rules={[{
                        required: true
                    }]}
                    initialValue='Solid'
                >
                    <Select options={[
                        {value: 'Solid', label: 'Solid'},
                        {value: 'Liquid', label: 'Liquid'},
                        {value: 'Gas', label: 'Gas'}
                    ]} className='CSFormInput' />
                </Form.Item>
                
                <Typography className='CSFormLabel mb-1'>Quantity of the chemical</Typography>
                <Form.Item
                    name='quantity'
                    rules={[{
                        required: true
                    }]}
                    initialValue={0}
                >
                    <Select options={[
                        {value: 0, label: 'Insufficient to cause any harm'},
                        {value: 4, label: 'Sufficient to cause serious harm'}
                    ]} className='CSFormInput' />
                </Form.Item>
                
                <Typography className='CSFormLabel mb-1'>Is the chemical stealable?</Typography>
                <Form.Item
                    name='stealable'
                    rules={[{
                        required: true
                    }]}
                    initialValue={0}
                >
                    <Select options={[
                        {value: 0, label: 'Can\'t be moved'},
                        {value: 1, label: 'Portable with special equipment'},
                        {value: 4, label: 'Easily portable'}
                    ]} className='CSFormInput' />
                </Form.Item>
                
                <Typography className='CSFormLabel mb-1'>Volatility</Typography>
                <Form.Item
                    name='volatile'
                    rules={[{
                        required: true
                    }]}
                    initialValue={0}
                >
                    <Select options={[
                        {value: 0, label: 'Solid, difficult to convert'},
                        {value: 1, label: 'Solid, easy to convert'},
                        {value: 2, label: 'Liquid, minimally volatile'},
                        {value: 3, label: 'Liquid, volatile'},
                        {value: 4, label: 'Gaseous'},
                    ]} className='CSFormInput' />
                </Form.Item>
                
                <Typography className='CSFormLabel mb-1'>Is chemical persistant in water?</Typography>
                <Form.Item
                    name='waterPersist'
                    rules={[{
                        required: true
                    }]}
                    initialValue={0}
                >
                    <Select options={[
                        {value: 0, label: 'Not Persistent'},
                        {value: 3, label: 'Persistent, but removable with extensive filtering'},
                        {value: 4, label: 'Persistent, water becomes impossible to decontaminate'}
                    ]} className='CSFormInput' />
                </Form.Item>
                
                <Typography className='CSFormLabel mb-1'>Is chemical persistant in soil?</Typography>
                <Form.Item
                    name='soilPersist'
                    rules={[{
                        required: true
                    }]}
                    initialValue={0}
                >
                    <Select options={[
                        {value: 0, label: 'Not Persistent'},
                        {value: 3, label: 'Persistent, but removable with extensive filtering'},
                        {value: 4, label: 'Persistent, water becomes impossible to decontaminate'}
                    ]} className='CSFormInput' />
                </Form.Item>
                
                <Typography className='CSFormLabel mb-1'>SDS</Typography>
                <Form.Item
                    name='hasSDS'
                >
                    <Upload maxCount={1} accept='.pdf' listType='picture-card' 
                        beforeUpload={() => false}
                    >
                        
                        <button
                        style={{
                            border: 0,
                            background: 'none',
                        }}
                        type="button"
                        >
                        <FontAwesomeIcon icon={faPlus} />
                        <div
                            style={{
                            marginTop: 8,
                            }}
                        >
                            Upload
                        </div>
                        </button>
                    </Upload>
                </Form.Item>

                <Flex justify='end' gap={15}>
                    <Button danger type='primary' htmlType='reset' onClick={() => setIsOpen(false)}>Cancel</Button>
                    <Button htmlType='submit'>Submit</Button>
                </Flex>
            </Form>
        </Modal>
    );
}