import React, { useState } from 'react';

import { Button, Flex, Form, Modal, Select, Typography } from 'antd';
import { doc, getFirestore, updateDoc } from '@firebase/firestore';
import { getStorage } from '@firebase/storage';
import { useSelector } from 'react-redux';

export default function QuickAnalysisModal (props) {
    const { assignedLab } = useSelector(state => state.authentication.userDetails);
    const { cas, isOpen, setIsOpen, notificationAPI, fetchToPage, initialValues } = props;
    const { quantity, stealable, volatile, waterPersist, soilPersist } = initialValues;

    const [chemicalForm] = Form.useForm();
    const [isUploading, setIsUploading] = useState(false); 

    const handleSubmit = async (vals) => {
        setIsUploading(true);

        const { quantity, soilPersist, stealable, volatile, waterPersist } = vals;
    
        try {
            const db = getFirestore();
            const storage = getStorage();

            // Upload the document first
            const chemDoc = doc(db, 'Chemicals', assignedLab, 'chemicals', cas);

            await updateDoc(chemDoc, {
                quantity: quantity,
                soilPersist: soilPersist,
                stealable: stealable,
                volatile: volatile,
                waterPersist: waterPersist
            });

            
            setIsUploading(false);
            setIsOpen(false);

            notificationAPI.success({
                message: 'Data Updated Successfully!'
            });

            chemicalForm.resetFields();

            fetchToPage();

        } catch (err) {
            notificationAPI.error({
                message: err.message === 'Failed to update fields!',
                description: err.message === 'An error occurred while attempting to update the chemical.'
            });
        } finally {
            setIsUploading(false);
        }
    }

    return (
        <Modal open={isOpen} footer={null} closeIcon={null}>
            <Form form={chemicalForm} onFinish={handleSubmit} disabled={isUploading}>
                <Typography className='oswald text-[24px] font-bold mb-2'>Quick Analysis Values</Typography>
                
                
                <Typography className='CSFormLabel mb-1'>Quantity of the chemical</Typography>
                <Form.Item
                    name='quantity'
                    rules={[{
                        required: true
                    }]}
                    initialValue={quantity}
                >
                    <Select options={[
                        {value: 0, label: 'Insufficient to cause any harm'},
                        {value: 4, label: 'Sufficient to cause serious harm'}
                    ]} className='CSFormInput' />
                </Form.Item>
                
                <Typography className='CSFormLabel mb-1'>Is the chemical stealable?</Typography>
                <Form.Item
                    name='stealable'
                    rules={[{
                        required: true
                    }]}
                    initialValue={stealable}
                >
                    <Select options={[
                        {value: 0, label: 'Can\'t be moved'},
                        {value: 1, label: 'Portable with special equipment'},
                        {value: 4, label: 'Easily portable'}
                    ]} className='CSFormInput' />
                </Form.Item>
                
                <Typography className='CSFormLabel mb-1'>Volatility</Typography>
                <Form.Item
                    name='volatile'
                    rules={[{
                        required: true
                    }]}
                    initialValue={volatile}
                >
                    <Select options={[
                        {value: 0, label: 'Solid, difficult to convert'},
                        {value: 1, label: 'Solid, easy to convert'},
                        {value: 2, label: 'Liquid, minimally volatile'},
                        {value: 3, label: 'Liquid, volatile'},
                        {value: 4, label: 'Gaseous'},
                    ]} className='CSFormInput' />
                </Form.Item>
                
                <Typography className='CSFormLabel mb-1'>Is chemical persistant in water?</Typography>
                <Form.Item
                    name='waterPersist'
                    rules={[{
                        required: true
                    }]}
                    initialValue={waterPersist}
                >
                    <Select options={[
                        {value: 0, label: 'Not Persistent'},
                        {value: 3, label: 'Persistent, but removable with extensive filtering'},
                        {value: 4, label: 'Persistent, water becomes impossible to decontaminate'}
                    ]} className='CSFormInput' />
                </Form.Item>
                
                <Typography className='CSFormLabel mb-1'>Is chemical persistant in soil?</Typography>
                <Form.Item
                    name='soilPersist'
                    rules={[{
                        required: true
                    }]}
                    initialValue={soilPersist}
                >
                    <Select options={[
                        {value: 0, label: 'Not Persistent'},
                        {value: 3, label: 'Persistent, but removable with extensive filtering'},
                        {value: 4, label: 'Persistent, water becomes impossible to decontaminate'}
                    ]} className='CSFormInput' />
                </Form.Item>
                
                <Flex justify='end' gap={15}>
                    <Button danger type='primary' htmlType='reset' onClick={() => setIsOpen(false)}>Cancel</Button>
                    <Button htmlType='submit'>Submit</Button>
                </Flex>
            </Form>
        </Modal>
    );
}