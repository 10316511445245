import React from 'react';

import { Typography, Flex } from 'antd';
import { useNavigate } from 'react-router-dom';

export default function LabSetupTable () {
    const navigate = useNavigate();

    const detailsHeaders = [
        {title: 'Chemical Receiving', key: 'receiving'}, 
        {title: 'Material Control', key: 'materialControl'}, 
        {title: 'Personnel Reliability', key: 'reliability'}, 
        {title: 'Physical Security', key: 'physicalSec'}, 
        {title: 'Information Security', key: 'infoSec'}, 
        {title: 'Chemical Disposal', key: 'disposal'}, 
        {title: 'Program Management', key: 'program'}
    ];

    return (
        <section>
            <Typography className='font-bold text-[32px] oswald ps-[5px]'>Lab Details</Typography>
            <div className="CSBorder CSBoxShadow !rounded-[5px]">
                <Flex justify='space-around'>
                    <Flex vertical className='max-h-[200px] py-3' wrap='wrap'>
                        {detailsHeaders.slice(0,3).map(
                            header => 
                                <Typography 
                                    className='oswald text-[#93BEDF] cursor-pointer w-fit font-bold text-[24px] underline mb-3' 
                                    onClick={_ => navigate('/setup/module', {state: header.key})}
                                >
                                    {header.title}
                                </Typography>)
                        }
                    </Flex>
                    <hr className='border-[#B6B6B5] rotate-[90deg] w-[90px] self-center'/>
                    <Flex vertical className='max-h-[200px] px-4 py-3' wrap='wrap'>
                        {detailsHeaders.slice(3,6).map(
                            header => 
                                <Typography 
                                    className='oswald text-[#93BEDF] cursor-pointer w-fit font-bold text-[24px] underline mb-3' 
                                    onClick={_ => navigate('/setup/module', {state: header.key})}
                                >
                                    {header.title}
                                </Typography>)
                        }
                    </Flex>
                    <hr className='border-[#B6B6B5] rotate-[90deg] w-[90px] self-center'/>
                    <Flex vertical className='max-h-[200px] px-4 py-3' wrap='wrap'>
                        {detailsHeaders.slice(6).map(
                            header => 
                                <Typography 
                                    className='oswald text-[#93BEDF] cursor-pointer w-fit font-bold text-[24px] underline mb-3' 
                                    onClick={_ => navigate('/setup/module', {state: header.key})}
                                >
                                    {header.title}
                                </Typography>)
                        }
                    </Flex>
                </Flex>
            </div>
        </section>
    );
}