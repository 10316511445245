import React, { useState } from 'react';

import { Avatar, Button, Flex, Form, Input, Space, Typography, Upload } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdBadge, faSpinner, faUpload, faUser } from '@fortawesome/free-solid-svg-icons';

export default function DetailsRegister(props) {
    const [isError, setIsError] = useState(false);    

    return (
        <Form 
            disabled={props.isLoading} 
            style={{
                width: '40vw',
                minWidth: '400px',
                padding: 30,
                // height: '60vh'
            }}
            align='center'
            vertical
            onFinish={vals => {
                if(!props.profilePic) {
                    setIsError(true);
                } else {
                    setIsError(false);
                    props.registerUser(vals);
                }
            }}
        >
            <Space size='large' align='center' className='px-1 mb-[20px]'>
                <Avatar id='userProfileFrame' className={`w-[8vw] h-[12vh] [&>img]:object-contain ${isError ? 'border-[red]' : 'border-black'}`} src={props.profilePic && URL.createObjectURL(props.profilePic)} icon={!props.profilePic && <FontAwesomeIcon icon={faUser} size='2x' /> } shape='square'  />
                <Flex vertical>
                    <Upload disabled={props.isLoading} accept='.png' maxCount={1} beforeUpload={(file => props.setProfilePic(file))} showUploadList={false}>
                        <Button disabled={props.isLoading} icon={<FontAwesomeIcon icon={faUpload} />}>Upload</Button>
                    </Upload>
                    { isError && <Typography className='text-red-600 text-[12px] font-light mt-[10px]'>Please upload a profile photo</Typography>}
                    <Typography className='text-gray-600 text-[12px] font-light mt-[10px]'>* Please use a clear photo of your face (Eg. passport photo)</Typography>
                </Flex>
            </Space>

            <Form.Item 
                id='name'
                name='name'
                rules={[
                    {
                        required: true,
                        message: 'Please enter your name!'
                    },
                ]}
            >
                <Input
                    showCount 
                    maxLength={20}
                    placeholder='Name'
                    prefix={<FontAwesomeIcon icon={faUser} className='me-[12px] accent' />}
                    className="CSInput oswald font-light text-[20px]"
                />
            </Form.Item>

            <Form.Item
                id='matric'
                name='matric'
                rules={[
                    {
                        required: true,
                        message: 'Please enter your matric number!'
                    },
                ]}
            >
                <Input 
                    showCount
                    maxLength={10}
                    placeholder='Matric No. (eg. student ID, staff ID)'
                    prefix={<FontAwesomeIcon icon={faIdBadge} className='me-[12px] accent' />}
                    className="CSInput oswald font-light text-[20px]"
                />
            </Form.Item>
        
            <Button
                htmlType='submit' 
                block 
                className='CSSuccess mt-[60px] mb-[32px]' 
                disabled={props.isLoading} 
                // onClick={handleSubmit}
            >
                {props.isLoading ? <FontAwesomeIcon icon={faSpinner} spinPulse /> : 'Register'}
            </Button>
        </Form>
    );
}