import React from 'react';

import { Flex, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory } from '@fortawesome/free-solid-svg-icons';

export default function PendingScreen() {
    return (
        <div className='mt-[50px]'>
            <Flex align='center' className='mb-[30px]'>
                <FontAwesomeIcon icon={faHistory} className='secondary me-[20px]' size='3x' /> 
                <Typography className='oswald font-semibold text-[42px]'>Pending Approval</Typography>
            </Flex>
            <Typography className='text-[#646464] oswald text-[42px]'>
                Your account is pending approval from the lab owner whose lab you registered for
            </Typography>
        </div>
    );
}