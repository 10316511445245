import { Timestamp } from "@firebase/firestore";
import { createSlice } from "@reduxjs/toolkit";

const defaultUserState = {
    accessLevel: 1,
    active: true,
    assignedLab: "USM001",
    email: null,
    timeStamp: Timestamp.now(),
    verified: false,
    verifiedDate: Timestamp.now(),
    uid: '09283'
};

export const userSlice = createSlice({
    name: 'authentication',
    initialState: defaultUserState,
    reducers: {
        setUserDetails: (state, action) => {
            state.userDetails = action.payload;
            state.originalLab = action.payload.assignedLab;
        },
        resetUserDetails: (state) => {
            state.userDetails = defaultUserState;
        },
        setSelectedLab: (state, action) => {
            state.userDetails.assignedLab = action.payload;
        }
    }
});

export const { setUserDetails, resetUserDetails, setSelectedLab } = userSlice.actions;

export default userSlice.reducer;