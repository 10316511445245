import React, { useState, useEffect } from 'react';

import { Typography, Switch, Space, Flex, Avatar } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSpinner, faUserAltSlash } from '@fortawesome/free-solid-svg-icons';
import { getFirestore, updateDoc, doc, Timestamp, getDocs, query, collection, where } from '@firebase/firestore';
import { useSelector } from 'react-redux'; 
import { getDownloadURL, getStorage, ref } from '@firebase/storage';

import StaffSearchModal from './StaffSearchModal';
import EmptyTableMessage from '../EmptyTableMessage';

export default function StaffTable (props) {
    const { assignedLab, accessLevel } = useSelector(state => state.authentication.userDetails);

    const [isLoading, setIsLoading] = useState(true);
    const [switchLoading, setSwitchLoading] = useState(false);
    const [switchUID, setSwitchUID] = useState(null);
    const [staffList, setStaffList] = useState([{
        email: <FontAwesomeIcon icon={faSpinner} spinPulse />,
        matric: '000000',
        verifiedDate: Timestamp.now(),
        active: false
    }]);
    // For the search modal option
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    

    const fetchStaff = async () => {
        try {
            const db = getFirestore();
            const storage = getStorage();

            const staffSnapshot = await getDocs(query(collection(db, 'users'), where('assignedLab', '==', assignedLab), 
                // If user is an administrator, display the lab owners too
                accessLevel === 3 ? 
                    where('accessLevel', '<=', 3) 
                    : 
                    where('accessLevel', '==', 1)
                , where('verified', '==', true)));
            
            const staffWithPfp = await Promise.all(staffSnapshot.docs.map(async staff => {
                return {
                    ...staff.data(),
                    id: staff.id,
                    pfp: await getDownloadURL(ref(storage, `users/${assignedLab}/${staff.id}/pfp.png`))
                }
            }))

            setStaffList(staffWithPfp);
        } catch (err) {
            // TODO: Implement error handling in some way
        }
    };

    useEffect(_ => {
        Promise.all([fetchStaff()]).then(_ => {
            setIsLoading(false);
        });
    }, [props.triggerStaffRender, assignedLab]);

    const handleSwitch = async (uid, val) => {
        try {
            setSwitchLoading(true);
            setSwitchUID(uid);
            const db = getFirestore();

            await updateDoc(doc(db, 'users', uid), {
                active: val
            });

            // If called from the search function, update the table
            const changedUserIndex = staffList.findIndex(user => user.id === uid);
            
            // Check if the element we want to update has been loaded or not
            if(changedUserIndex !== -1) {
                const newStaffList = [...staffList];
                newStaffList[changedUserIndex].active = val;
                setStaffList(newStaffList);
            }

        } catch (err) {

        } finally {
            setSwitchLoading(false);
            setSwitchUID(null);
        }
    };

    const staffHeaders = [
        <Space size='middle'>
            <Typography className='oswald text-[#646464] font-bold text-[24px]'>Name</Typography>
            <FontAwesomeIcon icon={faSearch} className='text-[#646464] cursor-pointer' fontWeight={null} onClick={() => setIsSearchOpen(true)} />
        </Space>,
        <Typography className='oswald text-[#646464] font-bold text-[24px]'>Matric No.</Typography>, 
        <Typography className='oswald text-[#646464] font-bold text-[24px]'>Role</Typography>, 
        <Typography className='oswald text-[#646464] font-bold text-[24px]'>Date Verified</Typography>, 
        <Typography className='oswald text-[#646464] font-bold text-[24px]'>Active</Typography>
    ];

    return (
        <section className='mb-[50px]'>
            <Typography className='oswald font-bold text-[28px] mb-[10px] ps-[5px]'>Staff List</Typography>
            
            {/* Staff Search Modal */}
            <StaffSearchModal isOpen={isSearchOpen} setIsOpen={setIsSearchOpen} handleSwitch={handleSwitch} />
            
            <div className='CSBoxShadow CSBorder !rounded-[5px] pb-2'>
                <div className="CSStaffGrid">
                    {staffHeaders}
                </div>
                <hr />

                <div className='max-h-[250px] overflow-y-scroll'>
                    {staffList.length === 0 ? <EmptyTableMessage message='No users assigned to this lab' icon={faUserAltSlash} /> : staffList.map(user => (
                        <div className="CSStaffGridContent px-1 py-2">
                            <Space size='middle'>
                                <Avatar shape='square' size='large' src={user.pfp} />
                                <Flex vertical={true}>
                                    <Typography className='roboto text-[18px]'>{isLoading ? <FontAwesomeIcon icon={faSpinner} spinPulse /> : user.name}</Typography>
                                    <Typography className='roboto text-[12px] font-light'>{isLoading ? <FontAwesomeIcon icon={faSpinner} spinPulse /> : user.email}</Typography>
                                </Flex>
                            </Space>
                            <Typography className='roboto text-[16px]'>{isLoading ? <FontAwesomeIcon icon={faSpinner} spinPulse /> : user.matric}</Typography>
                            <Typography className='roboto text-[16px]'>{isLoading ? <FontAwesomeIcon icon={faSpinner} spinPulse /> : getUserClearance(user.accessLevel)}</Typography>
                            <Typography className='roboto text-[16px]'>{isLoading ? <FontAwesomeIcon icon={faSpinner} spinPulse /> : (user.verifiedDate ?? Timestamp.now())?.toDate().toLocaleString()}</Typography>
                            {isLoading ? 
                                <FontAwesomeIcon icon={faSpinner} spinPulse /> 
                                :
                                <Switch onChange={val => handleSwitch(user.id, val)} value={user.active} disabled={switchLoading} loading={switchLoading && (user.id === switchUID)} className='w-[40px] border-black border-[1px]s' />
                            }
                        </div>
                    ))}
                </div>    
            </div>
        </section>
    );
}

function getUserClearance (lvl) {
    switch (lvl) {
        case 1:
            return 'Staff Member';
        case 2:
            return 'Lab Owner';
        case 3:
            return 'Administrator';
        default:
            return 'Guest';
    }
}