import React from 'react';

import { Link, useLocation } from 'react-router-dom';
import { Typography, Flex, Tooltip, Button  } from 'antd';
import {
    Chart as ChartJS,
    LinearScale,
    PointElement,
    LineElement,
    Legend,
    Title,
    CategoryScale,
    SubTitle,
  } from 'chart.js';
import { Scatter } from 'react-chartjs-2';
import ChemSAMBackground from '../../../images/ChemSAM-Background.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Legend, Title, SubTitle);

export default function ChemSAMChart (props) {
    const image = new Image();
    image.src = ChemSAMBackground;

    let { state } = useLocation();
    if(state === null) {
        state = {
            likeTheft: 0,
            likePop: 0,
            likeInd: 0,
            conTheft: 0,
            conPop: 0,
            conInd: 0,
            outsider: 0,
            insider: 0
        };
    }

    const {likeTheft, likePop, likeInd, conTheft, conPop, conInd, outsider, insider} = state;

    return (
        <div>
            <Flex justify='space-between' align='baseline'>
                <Typography className='oswald text-[48px] font-bold mb-[37px]'>Questionnaire</Typography>
                <Typography className='oswald text-[36px] font-bold'>ChemSAM - Results</Typography>
            </Flex>
            

            <Typography className='oswald text-[24px]'>Outsider Risk 
                <Tooltip className='ms-2' title='The threat that a person who does not work at the facility poses.'>
                    <FontAwesomeIcon size='xs' icon={faQuestionCircle} className='accent' />
                </Tooltip>
            </Typography>
            <div className="CSBoxShadow CSBorder w-[80vw] mb-[40px] mt-[15px]">
                <Scatter
                    data={getDataSet(outsider, likeTheft, likePop, likeInd, conTheft, conPop, conInd)} 
                    options= {getOptions('Facility Chemical Security Risk (Outsider)')}
                    plugins={getPlugins('outsider', image)}
                    key='outsiderChart'
                    id='outsiderChartID'
                />
            </div>
            
            <Typography className='oswald text-[24px]'>Insider Risk
                <Tooltip className='ms-2' title='The threat that an employee at the facility poses.'>
                    <FontAwesomeIcon size='xs' icon={faQuestionCircle} className='accent' />
                </Tooltip>
            </Typography>
            <div className="CSBoxShadow CSBorder w-[80vw] mb-[40px] mt-[15px]">
                <Scatter 
                    data={getDataSet(insider, likeTheft, likePop, likeInd, conTheft, conPop, conInd)} 
                    options = {getOptions('Facility Chemical Security Risk (Insider)')}
                    plugins={getPlugins('insider', image)}
                    key='insiderChart'
                    id='insiderChart'
                />
            </div>

            <Link to={'/'}>
                <Button type='primary' className='CSGreenButton mb-[30px]' onClick={_ => scrollToTop()}>Back to dashboard</Button>
            </Link>
        </div>
    );
}

function getOptions(title) {
    return {
        scales: {
            y: {
                beginAtZero: true, 
                max: 4,
                title: {
                    text: 'Likelihood of Success',
                    display: true
                }
            }, 
            x: {
                beginAtZero: true, 
                max: 4,
                title: {
                    text: 'Consequence of Malicious Use',
                    display: true
                }
            }
        },
        plugins: {
            title: {
                display: true,
                text: title,
                align: 'center'
            },
            legend: {
                align: 'center',
                position: 'right'
            }
        }
    };
}

function getDataSet(value, likeTheft, likePop, likeInd, conTheft, conPop, conInd) {
    return {
        datasets: [
            {
                label: 'Theft Risk',
                data: [{y: (likeTheft**0.5)*(value**0.5), x: conTheft}],
                backgroundColor: 'cyan',
                pointStyle: 'rectRot',
                pointRadius: 10
            },
            {
                label: 'Sabotage Risk - Near Populated Area',
                data: [{y: (likePop**0.5)*(value**0.5), x: conPop}],
                backgroundColor: 'maroon',
                pointStyle: 'triangle',
                pointRadius: 10
            },
            {
                label: 'Sabotage Risk - Near Industrial Area',
                data: [{y: (likeInd**0.5)*(value**0.5), x: conInd}],
                backgroundColor: 'orange',
                pointStyle: 'circle',
                pointRadius: 9
            }
        ]
    };
}

function getPlugins(chartID, image) {
    return [
        {
            id: chartID,
            beforeDraw: (chart) => {
                if (image.complete) {
                    const ctx = chart.ctx;
                    const {top, left, width, height} = chart.chartArea;
                    const x = left;
                    const y = top;
                    ctx.drawImage(image, x, y, width, height);
                    } else {
                    image.onload = () => chart.draw();
                    }
            }
        }
    ]
}

function scrollToTop() {
    document.getElementById('Main').scrollTo({top: 0, left: 0, behavior: 'smooth'});
}
