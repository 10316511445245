import React from 'react';

import { Flex, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function EmptyTableMessage(props) {

    return (
        <Flex justify='center' gap={15} align='center' className='h-[100px]'>
            <FontAwesomeIcon icon={props.icon} color='#d3d3d3' size='3x' />
            <Typography className='roboto font-bold text-[24px] text-[#d3d3d3]'>{props.message}</Typography>
        </Flex>
    );
}