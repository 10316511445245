import React, { useEffect, useState } from "react";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { ConfigProvider } from "antd";
import { getAuth } from "@firebase/auth";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import persistStore from "redux-persist/es/persistStore";

import { firebaseConfig } from "./KEYS";
import Login from './components/authentication/Login';
import Main from "./components/Main";
import userProvider from "./providers/userProvider";
import './App.css';


// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);
const persistUsers = persistStore(userProvider);

function App() {
  // Push any listeners to this and unsub from all once you log out 
  const [listeners, setListeners] = useState([]); 
  const [page, setPage] = useState(<></>);

  // Set up the listener to change the website when the user logs in and logs out
  useEffect(() => {
    const auth = getAuth(app);
    // auth.signOut();

    auth.onAuthStateChanged(user => {
      if (user) {
        // console.log('Yipee!! We have a user');
        setPage(<Main listeners={listeners} setListeners={setListeners} />)
      } else {
        // console.log('No user :(');
        setPage(<Login listeners={listeners} setListeners={setListeners} />)
      }
    })  
  }, [])


  return (
    <ConfigProvider theme={{
      components: {
        Input: {
          hoverBorderColor: '#93BEDF',
          activeBorderColor: '#6FB0FE',
        },
        Carousel: {
          colorBgContainer: '#1E1E24'
        },
        Menu: {
          iconSize: 28,
          fontFamily: 'Oswald',
          fontSize: 28,
          itemSelectedColor: '#F74707',
          itemColor: '#B6B6B5',
          colorHighlight: 'transparent',
          itemHoverBg: 'transparent',
          itemHoverColor: 'white',
          colorBgContainer: '#1E1F1C',
          itemSelectedBg: '#1E1F1C',
          controlItemBgActive: 'transparent',
          iconMarginInlineEnd: '0.6vw',
          padding: '0.5vw',
          itemMarginBlock: '3vh',
        },
        Layout: {
          siderBg: '#1E1F1C'
        }
      }
    }}>
      <Provider store={userProvider}>
        <PersistGate persistor={persistUsers}>
          {page}
        </PersistGate>
      </Provider>
    </ConfigProvider>
  )
}

export default App;
