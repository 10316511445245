import React from 'react';
import { Carousel, Typography, Flex } from 'antd';
import { faCheckSquare, faStopwatch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function CSCarousel () {
    return (
        <Carousel autoplay={true} dots={true} dotPosition='bottom' autoplaySpeed={5000} style={{maxWidth: '40vw', padding: 30}}>
            <Flex vertical={false} wrap="wrap">
                <Flex>
                    <FontAwesomeIcon className='secondary size-[48px]' icon={faCheckSquare} />
                    <Typography className="oswald font-bold ms-[30px] mb-[24px] text-[32px]">Convenient</Typography>
                </Flex>
                <Typography className="roboto font-light ps-[78px] text-[24px] text-justify">Perform Chemical Risk Analysis from the comfort of your seat through your browser or phone</Typography>
            </Flex>
            <Flex vertical={false} wrap="wrap">
                <Flex>
                    <div className="CSSquareIcon flex justify-center">
                        <FontAwesomeIcon className='size-[32px] self-center' icon={faStopwatch} color="white" />
                    </div>
                    <Typography className="oswald font-bold ms-[30px] mb-[24px] text-[32px]">Fast</Typography>
                </Flex>
                <Typography className="roboto font-light ps-[78px] text-[24px] text-justify">Improve the safety of your laboratory in a few minutes by answering the analysis questionnaire.</Typography>
            </Flex>
        </Carousel>
    );
}