import React, { useState } from 'react';

import { Modal, Select, Typography } from 'antd';

export default function AcceptUserModal(props) {
    const { isOpen, setIsOpen, onAcceptUser, selectedUID } = props;
    
    const [selectedRole, setSelectedRole] = useState(1);

    return (
        <Modal 
            open={isOpen} 
            closeIcon={false} 
            onCancel={() => {
                setIsOpen(false);
                setSelectedRole(1);
            }} 
            okType='default'
            okText='Accept User'
            cancelButtonProps={{danger: true, type: 'primary'}}
            onOk={() => {
                onAcceptUser(selectedUID, selectedRole);
                setIsOpen(false);
                setSelectedRole(1);
            }}
        >
            <Typography className='oswald text-[24px] font-bold mb-2'>Accept User</Typography>

            <Typography className=''>Please select user access level:</Typography>
            
            <Select value={selectedRole} onChange={(val) => setSelectedRole(val)}>
                <Select.Option value={1}> Staff Member </Select.Option>
                <Select.Option value={2}> Lab Owner </Select.Option>
            </Select>

        </Modal>
    );
}