import React, { useEffect, useState } from 'react';

import Typography from 'antd/es/typography/Typography';
import Dropdown from 'antd/es/dropdown/dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { collection, getDocs, getFirestore } from '@firebase/firestore';

import { setSelectedLab } from '../providers/userSlice';

export default function LabSelector() {
    const { assignedLab, accessLevel } = useSelector(state => state.authentication.userDetails);
    const dispatch = useDispatch();

    // The labs that are displayed in the dropdown
    const [labs, setLabs] = useState([{label: assignedLab, key: assignedLab}]);

    const fetchLabs = async () => {
        // If the user is a lab owner or staff, just return
        if (accessLevel < 3) {
            return;
        }

        try {
            const db = getFirestore();

            // Fetch the labs
            const collectedLabs = await getDocs(collection(db, 'labs'));

            setLabs(() => collectedLabs.docs.map(lab => {return {label: lab.id, key: lab.id};}));
        } catch (err) {

        }
    }

    useEffect(() => {
        fetchLabs();
    }, [assignedLab]);

    return (
        <Typography className='oswald text-[36px] font-semibold'>Lab Code: 
            {accessLevel < 3 ? 
                <Typography className='oswald text-[36px] font-normal inline'> {assignedLab} </Typography> 
                : 
                <Dropdown className='inline' menu={{
                items: labs, 
                selectable: true, 
                selectedKeys: assignedLab,
                onSelect: (val) => {
                    dispatch(setSelectedLab(val.key));
                }
            }}>
                <Typography className='oswald text-[36px] font-normal'> {assignedLab} </Typography>
            </Dropdown>}
        </Typography>
    );
}