import React from 'react';

import { Typography, Flex } from 'antd';
import { faHatHard, faWarning } from '@fortawesome/free-solid-svg-icons';

import QuestionnaireTile from './QuestionnaireTile';
import HIRARC from './HIRARC';
import ChemSAM from './ChemSAM/ChemSAM';

export default function Questionnaire () {

    const questionnaireList = [
        {
            title: 'HIRARC',
            icon: faHatHard,
            color: '#FFB11A',
            element: <HIRARC />
        },
        {
            title: 'Chem-SAM',
            icon: faWarning,
            color: '#F74707',
            element: <ChemSAM />
        }
    ]

    return (
        <div className='Questionnaire'>
            <Typography className='oswald text-[48px] font-bold'>Questionnaire</Typography>

            <Typography className='text-[32px] font-bold mt-[50px] ms-[6px] mb-[10px]'>Assessments</Typography>
            
            <Flex gap={45}>
                {/* This way we can get all the data dynamically? Need to find a way for icons */}
                {questionnaireList.map(({title, icon, color}) => {
                    return <QuestionnaireTile title={title} icon={icon} color={color} />
                })}
            </Flex>
        </div>
    ) 
}