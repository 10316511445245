import React, { useEffect, useState } from 'react';

import { Avatar, Button, Space, Typography, Upload } from 'antd';
import { useSelector } from 'react-redux';
import { getDownloadURL, getStorage, ref, uploadBytes } from '@firebase/storage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';

export default function Profile () {
    const { assignedLab, uid } = useSelector(state => state.authentication.userDetails);

    const [pfp, setPfp] = useState(null);
    const [tempPfp, setTempPfp] = useState(null);
    const [isUploading, setIsUploading] = useState(false);

    const fetchPfp = async () => {
        try {
            const storage = getStorage();
            const pfp = await getDownloadURL(ref(storage, `/users/${assignedLab}/${uid}/pfp.png`));
            
            setPfp(pfp);
        } catch (err) {

        }
    };

    const handleUpload = async () => {
        try {
            setIsUploading(true);
            const storage = getStorage();
            await uploadBytes(ref(storage, `users/${assignedLab}/${uid}/pfp.png`), tempPfp);

            setIsUploading(false);
        } catch (err) {

        }
    }

    useEffect(_ => {
        fetchPfp();
    }, [uid]);

    return (
        <div className='Profile w-[82vw]'>
            <Typography className='oswald text-[48px] font-bold mb-[60px]'>Profile</Typography>

            <Space size='large' align='start'>
                <Space direction='vertical' size='large'>
                    <Typography className='CSFormLabel !text-[32px]'>Profile Picture</Typography>
                    <Avatar src={tempPfp ? URL.createObjectURL(tempPfp) : pfp ?? ''} shape='square' className='w-[10vw] h-[20vh] border-black' />
                    <Upload accept='.png' maxCount={1} showUploadList={false} beforeUpload={(file, list) => setTempPfp(file)} disabled={isUploading} onChange={() => handleUpload()}>
                        <Button icon={<FontAwesomeIcon icon={faUpload} />}>Upload Image</Button>
                    </Upload>
                </Space>

                {/* <Typography className='CSFormLabel'>Username</Typography> */}
            </Space>

        </div>
    );
} 