import React, { useState, useEffect } from 'react';

import { getFirestore, getAggregateFromServer, collection, average, count } from '@firebase/firestore';
import { Flex, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

export default function StatisticsTile () {
    const [isLoading, setIsLoading] = useState(true);
    const [stats, setStats] = useState({
        totalAnalyses: 0,
        outsiderAvg: 0,
        insiderAvg: 0
    });
    const { assignedLab } = useSelector(state => state.authentication.userDetails);

    const fetchStats = async () => {
        try {
            const db = getFirestore();

            const statsSnapshot = await getAggregateFromServer(collection(db, 'ChemSAM', assignedLab, 'analyses'), {
                insiderAvg: average('insider'),
                outsiderAvg: average('outsider'),
                totalAnalyses: count()
            });

            setStats(statsSnapshot.data());
        } catch (err) {
            
        }
    };

    useEffect(_ => {
        Promise.all([fetchStats(assignedLab)]).then(_ => {
            setIsLoading(false);
        }).catch((err) => null);
    }, [assignedLab]);

    return (
        <section className='CSBoxShadow CSBorder px-3 py-2 mb-[30px]'>
            <Flex align='baseline' justify='space-evenly'>

                {/* Total Analyses */}
                {getStatTile(isLoading, 'Analyses', stats.totalAnalyses)}
                <hr className='rotate-[90deg] border-[#B7B7B7] border-[0.75px] w-[70px] self-center' />

                {/* Outsider Risk */}
                {getStatTile(isLoading, 'Outsider Risk', `${((stats.outsiderAvg ?? 0)/4).toFixed(4)*100}%`)}
                <hr className='rotate-[90deg] border-[#B7B7B7] border-[0.75px] w-[70px] self-center' />

                {/* Insider Risk */}
                {getStatTile(isLoading, 'Insider Risk', `${((stats.insiderAvg ?? 0)/4).toFixed(4)*100}%`)}
            </Flex>
        </section>
    );
}

function getStatTile (isLoading, title, value) {
    return (
        <Flex vertical>
            <Typography className='oswald font-bold side-drawer-color text-[28px]'>{title}</Typography>
            <Typography className='oswald font-bold text-[#646464] text-[32px]'>{
                isLoading ? 
                <FontAwesomeIcon icon={faSpinner} spinPulse /> 
                :
                value
            }</Typography>
        </Flex>
    );
}