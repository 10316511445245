import React from 'react';

import { Flex, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserSlash } from '@fortawesome/free-solid-svg-icons';

export default function DisabledScreen() {
    return (
        <div className='mt-[50px]'>
            <Flex align='center' className='mb-[30px]'>
                <FontAwesomeIcon icon={faUserSlash} className='text-yellow-500 me-[20px]' size='3x' /> 
                <Typography className='oswald font-semibold text-[42px]'>Account Disabled</Typography>
            </Flex>
            <Typography className='text-[#646464] oswald text-[42px]'>
                Your account has been disabled. Contact your lab owner to re-enable it.
            </Typography>
        </div>
    );
}