import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faFlaskVial, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Typography, Space, Button, Flex } from 'antd';
import { getStorage, ref,  getDownloadURL } from '@firebase/storage';
import { getFirestore, doc, getDocs, collection, getDoc, getCountFromServer, limit, query, startAfter, documentId, orderBy, endAt } from '@firebase/firestore';
import { useSelector } from 'react-redux';

import ChemicalModal from './ChemicalModal';
import MassImportButton from './MassImportButton';
import ChemicalRow from './ChemicalRow';
import ChemicalSearchModal from './ChemicalSearchModal';
import EmptyTableMessage from '../EmptyTableMessage';
import PagingCounter from '../PagingCounter';

export default function ChemicalsTable(props) {
    const { assignedLab } = useSelector(state => state.authentication.userDetails);

    // Control the state of the table
    const [isLoading, setIsLoading] = useState(true);
    const [isUploading, setIsUploading] = useState(false);

    // For chemical search modal sheet
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    
    // The chemicals that are fetched
    const [chemicals, setChemicals] = useState([]);

    // What is actually being displayed in the table
    const [view, setView] = useState([{
        cas: '000-000-000',
        name: 'unknown chemical',
        state: 'Solid'
    }]);
    
    // For paginating the data
    const [chemicalCount, setChemicalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    
    // For the add chmemical modal
    const [isChemicalModalOpen, setIsChemicalModalOpen] = useState(false);

    const fetchChemicals = async (page= 1, reset= false) => {
        try {
            setIsLoading(true);

            const db = getFirestore();
            const storage = getStorage();

            let chemicalsSnap = null;
            const chemicalsColl = collection(db, 'Chemicals', assignedLab, 'chemicals');

            // Is the requested range already in the array?
            if(reset || !chemicals[(page*10)-1]) {
                // Is the array empty? Is this the first run of the page?
                const pivot = chemicals.length === 0 || reset ? null : await getDoc(doc(db, 'Chemicals', assignedLab, 'chemicals', chemicals[chemicals.length-1].id));
                
                // If yes, fetch the first 10 elements from the DB
                if (chemicals.length <= 10 || reset) {
                    chemicalsSnap = await getDocs(query(chemicalsColl, orderBy(documentId()), limit(10)));
                } else { // Otherwise, fetch the next 10 items based on the last element in the existing array
                    chemicalsSnap = await getDocs(query(chemicalsColl, orderBy(documentId()), startAfter(pivot), limit(10)));
                }
                
                // Grab the rest of the details accordingly
                const chemicalsData = await Promise.all(chemicalsSnap.docs.map(async (chem) => {
                    return {
                        ...chem.data(),
                        downloadLink: chem.data().hasSDS ? await getDownloadURL(ref(storage, `/chemicals/${assignedLab}/SDS/${chem.id}.pdf`)) : '',
                        id: chem.id
                    }
                }));

                // Add the new data to the existing chemicals and set the page view
                setChemicals([...chemicals, ...chemicalsData]);
                setView(chemicalsData);
            } else {
                // Just fetch the items from the list and display them
                setView(chemicals.slice((page-1)*10, page*10-1)); 
            }

            if(reset) {
                // If this is a reset, place the current page at 1
                setCurrentPage(1);
            }

            // Update the count of the total number of pages
            const chemicalsCollSnap = await getCountFromServer(chemicalsColl);
            setChemicalCount(chemicalsCollSnap.data().count);
            
            setIsLoading(false);
        } catch (err) {
            // console.log(err);
        }
    }

    const fetchToPage = async (page= 1) => {
        try {
            setIsLoading(true);

            const db = getFirestore();
            const storage = getStorage();

            let chemicalsSnap = null;
            const chemicalsColl = collection(db, 'Chemicals', assignedLab, 'chemicals');

            // Is the array empty? Is this the first run of the page?
            
            chemicalsSnap = await getDocs(query(chemicalsColl, orderBy(documentId()), limit(page*10)));
            
            // Grab the rest of the details accordingly
            const chemicalsData = await Promise.all(chemicalsSnap.docs.map(async (chem) => {
                return {
                    ...chem.data(),
                    downloadLink: chem.data().hasSDS ? await getDownloadURL(ref(storage, `/chemicals/${assignedLab}/SDS/${chem.id}.pdf`)) : '',
                    id: chem.id
                }
            }));

            // Add the new data to the existing chemicals and set the page view
            setChemicals(chemicalsData);
            setView(chemicalsData.slice((page-1)*10));

            setCurrentPage(page);

            // Update the count of the total number of pages
            const chemicalsCollSnap = await getCountFromServer(chemicalsColl);
            setChemicalCount(chemicalsCollSnap.data().count);
            
            setIsLoading(false);
        } catch (err) {
            // console.log(err);
        }
    }

    useEffect(_ => {
        Promise.all([fetchChemicals(1, true)]).then(_ => {
            setIsLoading(false);
        })
    }, [assignedLab]);    

    const chemicalHeaders = [
        <Space size='middle'>
            <Typography className='oswald text-[#646464] font-bold text-[24px]'>CAS No.</Typography> 
            <FontAwesomeIcon icon={faSearch} className='text-[#646464] cursor-pointer'  fontWeight={null} onClick={() => setIsSearchOpen(true)}/>
        </Space>,
        <Typography className='oswald text-[#646464] font-bold text-[24px]'>Name</Typography>,
        <Typography className='oswald text-[#646464] font-bold text-[24px]'>State</Typography>, 
        <Typography className='oswald text-[#646464] font-bold text-[24px]'>Status</Typography>, 
        <Typography className='oswald text-[#646464] font-bold text-[24px]'>Quick Analysis</Typography>
    ];

    return (
        <section className='mb-[40px]'>
            <Flex justify='space-between'>
                <Typography className='font-bold text-[32px] oswald ps-[5px]'>Chemicals</Typography>

                <Space>
                    <Button icon={<FontAwesomeIcon icon={faAdd} />} onClick={() => setIsChemicalModalOpen(true)}>Add Chemical</Button>
                    <MassImportButton isUploading={isUploading} setIsUploading={setIsUploading} notificationAPI={props.notificationAPI} />
                </Space>
            </Flex>
            
            {/* Modal sheets */}
            <ChemicalModal isOpen={isChemicalModalOpen} setIsOpen={setIsChemicalModalOpen} notificationAPI={props.notificationAPI} fetchToPage={() => fetchToPage(currentPage)} />
            <ChemicalSearchModal isOpen={isSearchOpen} setIsOpen={setIsSearchOpen} />
            
            <div className="CSBorder CSBoxShadow !rounded-[5px]">
                <div className='CSChemGrid py-1'>
                    {chemicalHeaders}
                </div>
                <hr />

                <div className="max-h-[250px] overflow-y-scroll">
                    {/* Map the items here */}
                    {view.length === 0 ? <EmptyTableMessage message='No chemicals assigned to this lab' icon={faFlaskVial} /> : view.map((chem, i) => <ChemicalRow isLoading={isLoading} chem={chem} setIsUploading={setIsUploading} isUploading={isUploading} fetchToPage={() => fetchToPage(currentPage)} notificationAPI={props.notificationAPI} />)}
                </div>
            </div>
            
            <PagingCounter currentPage={currentPage} totalPage={Math.ceil(chemicalCount / 10)} isLoading={isLoading} moveBack={(_) => {
                if(currentPage > 1) {
                    fetchChemicals(currentPage-1);
                    setCurrentPage(currentPage-1);
                }
            }} moveNext={(_) => {
                if(currentPage < (chemicalCount / 10).toFixed(0)) {
                    fetchChemicals(currentPage+1);
                    setCurrentPage(currentPage+1);
                }
            }}/>
        </section>
    );
}