import React, { useEffect, useState } from 'react';

import { Flex, Input, Typography, Form, Button, Radio, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'antd/es/form/Form';
import { getFirestore, updateDoc, doc, addDoc, collection, Timestamp } from '@firebase/firestore';
import { getAuth } from '@firebase/auth';
import { getBlob, getStorage, ref } from '@firebase/storage';
import { useSelector } from 'react-redux';
import pdfToText from 'react-pdftotext';

import analyseChemical from '../../../JSON/GHS';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

export default function ChemSAMForm (props) {
    const {section, title, questions} = props.data;
    const [loading, setLoading] = useState(false);
    const [canQuickCalc, setCanQuickCalc] = useState(false);
    const [selectedLab, setSelectedLab] = useState(null);
    const [selectedChem, setSelectedChem] = useState(null);
    const [form] = useForm();
    const navigate = useNavigate();
    const { assignedLab } = useSelector(state => state.authentication.userDetails);

    useEffect(() => {
        form.setFieldsValue(props.data.data);
    }, [props.currentPage]);

    const handleFormSubmit = (vals) => {
        const newPages = [...props.pages];

        const {theftRisk, populatedRisk, industrialRisk, outsiderRisk, insiderRisk} = calculateRisk(vals, newPages, props);

        // Store the caluclation results
        newPages[props.currentPage].data = {
            theftRisk: isNaN(theftRisk) ? 0 : theftRisk, 
            populatedRisk: isNaN(populatedRisk) ? 0 : populatedRisk, 
            industrialRisk: isNaN(industrialRisk) ? 0 : industrialRisk, 
            outsiderRisk: isNaN(outsiderRisk) ? 0 : outsiderRisk, 
            insiderRisk: isNaN(insiderRisk) ? 0 : insiderRisk, 
            ...vals
        };

        // Update data
        props.setPages(newPages);
        
        if(props.currentPage+1 === props.pages.length) {
            // Calculate the entire thing here and return it for showing table
            
            // Likelihood of target -> theft, pop, indust
            const likelihood = newPages.filter(el => el.key === 'likelihood')[0].data;
            
            // Consequences of Mal -> theft, pop, indust  
            let theftRisk = 0; 
            let populatedRisk = 0;
            let industrialRisk = 0;
            let outsiderRisk = 0;
            let insiderRisk = 0;
            
            newPages.forEach(page => {
                if (page.key !== 'likelihood') {
                    // Update all the values
                    theftRisk += valueOrZero(page.data.theftRisk);
                    populatedRisk += valueOrZero(page.data.populatedRisk);
                    industrialRisk += valueOrZero(page.data.industrialRisk);
                }
                outsiderRisk += valueOrZero(page.data.outsiderRisk);
                insiderRisk += valueOrZero(page.data.insiderRisk);
            });

            Promise.all([updateDatabase(newPages, likelihood.theftRisk, likelihood.populatedRisk, likelihood.industrialRisk, theftRisk, populatedRisk, industrialRisk, outsiderRisk, insiderRisk)]).then(_ => {
                navigate('/questionnaire/Chem-SAM/result', {state: {
                    likeTheft: likelihood.theftRisk,
                    likePop: likelihood.populatedRisk,
                    likeInd: likelihood.industrialRisk,
                    conTheft: theftRisk,
                    conPop: populatedRisk,
                    conInd: industrialRisk,
                    outsider: outsiderRisk,
                    insider: insiderRisk
                }});
            }).catch(err => props.notificationAPI.error({
                message: 'Failed to upload data, please check your internet connection',
                duration: 300
            }));
            
        } else {
            props.setCurrentPage(props.currentPage+1);
        }
        scrollToTop();
    }


    return (
        <article>
            <Typography className='oswald text-[32px] ps-[6px] font-bold mb-[23px]'>ChemSAM - {section}</Typography>

            <div className='CSBoxShadow CSBorder w-[80vw] px-8 py-6 mb-[30px]'>
                <Typography className='oswald text-[24px] font-bold mb-[23px]'>{title}</Typography>

                <Form
                    onFinish={handleFormSubmit}
                    disabled={loading}
                    form = {form}
                    initialValues={props.data.data}
                >
                    
                    {/* The Questions */}
                    <Flex wrap='wrap' initialValue={props.data}>
                    {questions.map(({title, type, name, options}) => 
                        <div className='w-[50%]'>
                            <Typography className='CSFormLabel mb-1'>{title}</Typography>
                            {/* This subtitle should only be displayed in the case of OPCW chemicals question */}
                            {name === 'opcw' && <Typography className='font-light mb-2'>Please refer to your country's nationally regulated chemicals list</Typography>}
                            
                            {generateField({name, type, options, pages: props.pages, setPages: props.setPages, Chemicals: props.Chemicals, LabData: props.Labs, fetchChemicals: props.fetchChemicals, assignedLab: assignedLab, setLoading: setLoading, setCanQuickCalc: setCanQuickCalc, setSelectedLab, setSelectedChem})}
                            
                            {/* In the case where a lab was selected that doesn't have any chemicals */}
                            {name === 'cas' && selectedLab && Object.keys(props.Chemicals).length === 0 && <Typography className='froboto font-bold text-[24px] text-[#d3d3d3] pb-5'><FontAwesomeIcon icon={faTriangleExclamation} /> No chemicals available for analysis in this lab</Typography>}
                        </div>
                    )}
                    </Flex>

                    <hr className='mb-7 me-5 CSDivider' />

                    {/* Action Buttons */}
                    <Flex gap={24} justify='flex-end' className='me-5'>
                        <Button danger type='primary' className='w-[125px] h-[42px] rounded-[15px] roboto font-bold text-[24px]'
                            onClick={() => {
                                if(props.currentPage === 0) {
                                    navigate('/questionnaire');
                                } else {
                                    props.setCurrentPage(props.currentPage-1);
                                }
                                scrollToTop();
                            }}
                        >{props.currentPage === 0 ? 'Cancel' : 'Back'}</Button>
                        <Button disabled={!canQuickCalc || loading} className='h-[42px] rounded-[15px] CSBlueButton' onClick={() => {
                            performAnalysis(props.pages, navigate, props.notificationAPI, selectedLab, selectedChem);
                            }}>
                            Quick Analysis
                        </Button>
                        <Button className='w-[125px] CSGreenButton' htmlType='submit'>
                            {props.currentPage+1 === props.pages.length ? 'Submit' : 'Next'}
                        </Button>
                    </Flex>
                </Form>
            </div>
        </article>
    );
}

function scrollToTop() {
    document.getElementById('Main').scrollTo({top: 0, left: 0, behavior: 'smooth'});
}

function valueOrZero (val) {
    return val === null || val === undefined || isNaN(val) ? 0 : val;
}

async function updateDatabase (newPages, likeTheft, likePop, likeInd, conTheft, conPop, conInd, outsider, insider) {
    // Upload all the data here
    try {
        // Easier to access for uploading
        const dataMap = {};

        newPages.forEach((item) => {
            dataMap[item.key] = item.data;
        });

        const db = getFirestore();
        const auth = getAuth();
        
        // Upload to the chemical list first
        await updateDoc(doc(db, 'Chemicals', dataMap.setup.lab, 'chemicals', dataMap.setup.cas), {
            details: {
                likelihood: dataMap.likelihood,
                consequence: dataMap.consequence,
                impact: dataMap.impact
            }
        });

        // Upload to the lab data section
        await updateDoc(doc(db, 'labs', dataMap.setup.lab), {
            security: {
                receiving: dataMap.receiving,
                materialControl: dataMap.materialControl,
                reliability: dataMap.reliability,
                physicalSec: dataMap.physicalSec,
                infoSec: dataMap.infoSec,
                disposal: dataMap.disposal,
                program: dataMap.program
            }
        });

        // Add this analysis to the ChemSAM collection
        await addDoc(collection(db, 'ChemSAM', dataMap.setup.lab, 'analyses'), {
            chemical: dataMap.setup.cas,
            timeStamp: Timestamp.now(),
            user: auth.currentUser.uid,
            likeTheft: likeTheft,
            likePop: likePop,
            likeInd: likeInd,
            conTheft: conTheft,
            conPop: conPop,
            conInd: conInd,
            outsider: outsider,
            insider: insider
        });

    } catch (err) {
        throw(err);
    }
}

function handleSelectItem (val, itemList, setPages, pages, chemical, assignedLab, setLoading, labs, setCanQuickCalc) {
    if(itemList[val] !== undefined) {
        const newPages = [...pages];

        // Extract the data from the SDS
        if(chemical) {
            try {
                setLoading(true);

                // Get the SDS from storage
                const storage = getStorage();
                getBlob(ref(storage, `/chemicals/${assignedLab}/SDS/${val}.pdf`)).then(res => {
                    const labels = [];
                    console.log(res);
                    // Extract the data from the SDS
                    pdfToText(res).then(out => {
                        const results = [...out.matchAll(/H\d{3}/g)];
                        results.forEach(el => labels.includes(el[0]) ? null : labels.push(el[0]));


                        // Set the data so that the form can read it
                        const analysisOut = analyseChemical({cas: val, labels: labels, state: itemList[val].state});
                    
                        // Set the values from the databse for special fields
                        const { quantity, soilPersist, stealable, volatile, waterPersist } = itemList[val];
                        analysisOut.likelihood.quantity = quantity;
                        analysisOut.likelihood.stealable = stealable;
                        analysisOut.likelihood.volatile = volatile;
                        analysisOut.impact.soilPersist = soilPersist;
                        analysisOut.impact.waterPersist = waterPersist;

                        Object.keys(analysisOut).forEach(section => {
                            // Find the item in the ChemSAM array
                            const requiredItem = newPages.filter(item => item.key === section)[0];
                            requiredItem.data = analysisOut[section];
                        });
                    });
                    
                    setCanQuickCalc(isQuickPossible(pages, labs[assignedLab], itemList[val]));
                    setLoading(false);
                });
            } catch (err) {
                // console.log('An error occurred', err);
            }
        } else {
            // Lab, since not chemical
            const requiredMap = itemList[val];
            Object.keys(requiredMap).forEach(section => {
                // Set the respective value from the DB retrieved
                const requiredItem = newPages.filter(item => item.key === section)[0];
                requiredItem.data = itemList[val][section] === undefined ? {} : itemList[val][section];
            });
        }

        setPages(newPages);
    };
}

function generateField ({name, type, options, pages, setPages, Chemicals, LabData, fetchChemicals, assignedLab, setLoading, setCanQuickCalc, setSelectedLab, setSelectedChem}) {
    
    let field = <></>;

    switch(type) {
        case 'radio':
            field = <Radio.Group >
                {options.map(opt => <Radio style={{display: 'block'}} value={opt.value}>{opt.title}</Radio>)}
            </Radio.Group>
            break;
            
        case 'radio button':
            field = <Radio.Group >
                {options.map(opt => <Radio.Button value={opt.value}>{opt.title}</Radio.Button>)}
            </Radio.Group>
            break;

        case 'select':
            let handleSelect = () => {};
            let selectOptions = [];

            if (name === 'cas') {
                handleSelect = val => {
                    setSelectedChem(val);
                    handleSelectItem(val, Chemicals, setPages, pages, true, assignedLab, setLoading, LabData, setCanQuickCalc);
                };
                selectOptions = Object.keys(Chemicals).map(item => <Select.Option value={item}>{`${item} [${Chemicals[item].name}]`}</Select.Option>);

            } else if (name === 'lab') {
                handleSelect = (val) => {
                    setSelectedLab(val);
                    handleSelectItem(val, LabData, setPages, pages, false);
                    fetchChemicals(val);
                };
                selectOptions = Object.keys(LabData).map(item => <Select.Option value={item}>{item}</Select.Option>);
            }

            field = <Select onSelect={handleSelect} disabled={selectOptions.length === 0}>
                    {selectOptions}
                </Select>
            break;

        default:
            field = <Input type={type} />
    }

    return <Form.Item className='pe-5' name={name} rules={[{required: true}]}>
        {field}
    </Form.Item>
}

function calculateRisk (vals, newPages, props) {
    let theftRisk = 0;
    let populatedRisk = 0;
    let industrialRisk = 0;
    let outsiderRisk = 0;
    let insiderRisk = 0;
    
    const multipliersMap = {};
    
    newPages[props.currentPage].questions.forEach((e) => {
        multipliersMap[e.name] = {
            theftMult: e.theftMult,
            populatedMult: e.populatedMult,
            industrialMult: e.industrialMult,
            outsiderMult: e.outsiderMult,
            insiderMult: e.insiderMult
        }
    })

    // Calculate the risk associated with the page
    newPages[props.currentPage].questions.forEach(question => {
        theftRisk += vals[question.name] * question.theftMult;
        populatedRisk += vals[question.name] * question.populatedMult;
        industrialRisk += vals[question.name] * question.industrialMult;
        outsiderRisk += vals[question.name] * question.outsiderMult;
        insiderRisk += vals[question.name] * question.insiderMult;
    });

    // Special calculation for some areas
    if (props.data.key === 'likelihood') {
        const rightSide = (
            (vals.quantity * multipliersMap.quantity.theftMult) + 
            (vals.stealable * multipliersMap.stealable.theftMult) 
        ) ** 0.5;
        const leftSide = (
            (vals.opcw * multipliersMap.opcw.theftMult) +
            (vals.toxic * multipliersMap.toxic.theftMult) +
            (vals.volatile * multipliersMap.volatile.theftMult) +
            (vals.isSolid * multipliersMap.isSolid.theftMult) +
            (vals.isLiquid * multipliersMap.isLiquid.theftMult) +
            (vals.isGas * multipliersMap.isGas.theftMult) 
        ) ** 0.5;

        theftRisk = rightSide * leftSide;

    // Custom addition for the first section second half
    // TODO: change this to a function that works based on the the name
    } else if (props.data.key === 'consequence') {
        // Theft Risk
        const inhalTheft = (
            (vals.inhalationDeath * multipliersMap.inhalationDeath.theftMult) + 
            (vals.inhalationDistress * multipliersMap.inhalationDistress.theftMult)
        ) * multipliersMap.inhalationIllness.theftMult;
        const ingesTheft = (
            (vals.ingestionDeath * multipliersMap.ingestionDeath.theftMult) + 
            (vals.ingestionDistress * multipliersMap.ingestionDistress.theftMult)
        ) * multipliersMap.ingestionIllness.theftMult;
        const contaTheft = (
            (vals.physicalDeath * multipliersMap.physicalDeath.theftMult) + 
            (vals.physicalDistress * multipliersMap.physicalDistress.theftMult)
        ) * multipliersMap.physicalIllness.theftMult;
        theftRisk = inhalTheft + ingesTheft + contaTheft;
        
        // Populated Risk
        const inhalPopulated = (
            (vals.inhalationDeath * multipliersMap.inhalationDeath.populatedMult) + 
            (vals.inhalationDistress * multipliersMap.inhalationDistress.populatedMult)
        ) * multipliersMap.inhalationIllness.populatedMult;
        const ingesPopulated = (
            (vals.ingestionDeath * multipliersMap.ingestionDeath.populatedMult) + 
            (vals.ingestionDistress * multipliersMap.ingestionDistress.populatedMult)
        ) * multipliersMap.ingestionIllness.populatedMult;
        const contaPopulated = (
            (vals.physicalDeath * multipliersMap.physicalDeath.populatedMult) + 
            (vals.physicalDistress * multipliersMap.physicalDistress.populatedMult)
        ) * multipliersMap.physicalIllness.populatedMult;
        populatedRisk = inhalPopulated + ingesPopulated + contaPopulated;
        
        // Industrial Risk
        const inhalIndust = (
            (vals.inhalationDeath * multipliersMap.inhalationDeath.industrialMult) + 
            (vals.inhalationDistress * multipliersMap.inhalationDistress.industrialMult)
        ) * multipliersMap.inhalationIllness.industrialMult;
        const ingesIndust = (
            (vals.ingestionDeath * multipliersMap.ingestionDeath.industrialMult) + 
            (vals.ingestionDistress * multipliersMap.ingestionDistress.industrialMult)
        ) * multipliersMap.ingestionIllness.industrialMult;
        const contaIndust = (
            (vals.physicalDeath * multipliersMap.physicalDeath.industrialMult) + 
            (vals.physicalDistress * multipliersMap.physicalDistress.industrialMult)
        ) * multipliersMap.physicalIllness.industrialMult;
        industrialRisk = inhalIndust + ingesIndust + contaIndust;
    }

    return {theftRisk, populatedRisk, industrialRisk, outsiderRisk, insiderRisk};
}

function isQuickPossible(pages, labData, chemicalData) {
    const labPages = pages.filter(it => it.dictatedByChem === false);
    // To make sure the value is valid
    labData = labData === undefined || labData === null ? {} : labData;
    const labDataKeys = Object.keys(labData);

    // Make sure all the values of the lab are there
    let breakLab = false;
    labPages.forEach(({key, questions}) => {
        if(!(labDataKeys.includes(key))) {
            breakLab = true;
            return false;
            // return 'No section';
        }

        questions.forEach(question => {
            if(!(question.name in labData[key])) {
                breakLab = true;
                return false;
                // return 'No item';
            }
        });
    });

    // Not possible if the lab has missing values
    if(breakLab) {
        return false;
    }

    const {quantity, soilPersist, stealable, volatile, waterPersist} = chemicalData;

    // Chemical needs to have special values set up
    if(!(quantity !== null && soilPersist !== null && stealable !== null && volatile !== null && waterPersist !== null)) {
        return false;
        // return 'No special vals';
    }
    
    return true;
}

function performAnalysis(pages, navigate, notificationAPI, lab, chem) {
    const newPages = pages;
    newPages[0].data = {lab: lab, cas: chem};
    // console.log(newPages);
    newPages.forEach((page, currentPage) => {
        const {theftRisk, populatedRisk, industrialRisk, outsiderRisk, insiderRisk} = calculateRisk(page.data, newPages, {currentPage: currentPage, data: newPages[currentPage]});
    
        // Store the caluclation results
        page.data = {
            theftRisk: isNaN(theftRisk) ? 0 : theftRisk, 
            populatedRisk: isNaN(populatedRisk) ? 0 : populatedRisk, 
            industrialRisk: isNaN(industrialRisk) ? 0 : industrialRisk, 
            outsiderRisk: isNaN(outsiderRisk) ? 0 : outsiderRisk, 
            insiderRisk: isNaN(insiderRisk) ? 0 : insiderRisk, 
            ...page.data 
        };
    });

    // Calculate the entire thing here and return it for showing table
            
    // Likelihood of target -> theft, pop, indust
    const likelihood = newPages.filter(el => el.key === 'likelihood')[0].data;
    
    // Consequences of Mal -> theft, pop, indust  
    let theftRisk = 0; 
    let populatedRisk = 0;
    let industrialRisk = 0;
    let outsiderRisk = 0;
    let insiderRisk = 0;
    
    newPages.forEach(page => {
        if (page.key !== 'likelihood') {
            // Update all the values
            theftRisk += valueOrZero(page.data.theftRisk);
            populatedRisk += valueOrZero(page.data.populatedRisk);
            industrialRisk += valueOrZero(page.data.industrialRisk);
        }
        outsiderRisk += valueOrZero(page.data.outsiderRisk);
        insiderRisk += valueOrZero(page.data.insiderRisk);
    });

    Promise.all([updateDatabase(newPages, likelihood.theftRisk, likelihood.populatedRisk, likelihood.industrialRisk, theftRisk, populatedRisk, industrialRisk, outsiderRisk, insiderRisk)]).then(_ => {
        navigate('/questionnaire/Chem-SAM/result', {state: {
            likeTheft: likelihood.theftRisk,
            likePop: likelihood.populatedRisk,
            likeInd: likelihood.industrialRisk,
            conTheft: theftRisk,
            conPop: populatedRisk,
            conInd: industrialRisk,
            outsider: outsiderRisk,
            insider: insiderRisk
        }});
    }).catch(err => {
        notificationAPI.error({
            message: 'Failed to upload data, please check your internet connection',
            duration: 300
        });
    });
}