export default function analyseChemical({cas, labels, state}) {
    const GHSOut = getGHSCategories(labels);

    const out = {
        likelihood: {
            flammable: GHSOut.likelihood.flammable ? 4 : 0,
            toxic: GHSOut.likelihood.toxic ? 4 : 0,
            isSolid: state.toLowerCase().includes('solid') ? 4 : 0,
            isLiquid: state.toLowerCase().includes('liquid') ? 4 : 0,
            isGas: state.toLowerCase().includes('gas') ? 4 : 0,
            opcw: getOPCW(cas)
        },
        consequence: GHSOut.consequence,
        impact: {
            hazardous: GHSOut.impact.hazardous ? 4 : 0,
            weaponization: GHSOut.impact.weaponization ? 4 : 0
        }
    };

    return out;
}

export function getGHSCategories (labels) {
    const categories = {
        likelihood: {
            flammable: false,
            toxic: false,
        },
        consequence: {
            
            ingestionIllness: 0,
            ingestionDeath: 0,
            ingestionDistress: 0,
            
            inhalationIllness: 0,
            inhalationDeath: 0,
            inhalationDistress: 0,
            
            physicalIllness: 0,
            physicalDeath: 0,
            physicalDistress: 0
        },
        impact: {
            hazardous: false,
            weaponization: false,
        }
    };

    labels.forEach(label => {
        // Check flammable
        if(!categories.likelihood.flammable && flammableLabels.includes(label)) {
            categories.likelihood.flammable = true;
        }
        
        // Check toxic
        if(!categories.likelihood.toxic && toxicLabels.includes(label)) {
            categories.likelihood.toxic = true;
        }

        // Check chronic exposure
        if(!categories.impact.hazardous && exposureLabels.includes(label)) {
            categories.impact.hazardous = true;
        }

        // History of weaponization
        if(!categories.impact.weaponization && wassenarList.includes(label)) {
            categories.impact.weaponization = true;
        }

        // Check for ingestion death, illness, and distress
        Object.keys(categories.consequence).forEach(key => {
            const origKey = key;
            if(key.includes('inhalation')) {
                key = key.split('inhalation')[1].toLowerCase();
                const currVal = ingestionLabels[key].certain.includes(label) ? 4 : ingestionLabels[key].largeQuantity?.includes(label) ? 2 : 0;
                if(currVal > categories.consequence[origKey]) {
                    categories.consequence[origKey] = currVal;
                }

            } else if (key.includes('ingestion')) {
                key = key.split('ingestion')[1].toLowerCase();
                const currVal = inhalationLabels[key].certain.includes(label) ? 4 : inhalationLabels[key].largeQuantity?.includes(label) ? 2 : 0;
                if(currVal > categories.consequence[origKey]) {
                    categories.consequence[origKey] = currVal;
                }

            } else {
                key = key.split('physical')[1].toLowerCase();
                const currVal = contactLabels[key].certain.includes(label) ? 4 : contactLabels[key].largeQuantity?.includes(label) ? 2 : 0;
                if(currVal > categories.consequence[origKey]) {
                    categories.consequence[origKey] = currVal;
                }

            }
        });
    });

    return categories;
}

export function getOPCW (cas) {
    const currVal = schedule_1.includes(cas) ? 4 : schedule_2.includes(cas) || schedule_3.includes(cas) ? 3 : TICs.includes(cas) ? 1 : 0;

    return currVal;
};

const flammableLabels = ['H220', 'H221', 'H222', 'H223', 'H224', 'H225', 'H226', 'H228', 'H250', 'H260', 'H261'];
const toxicLabels = ['H300', 'H301', 'H310', 'H311', 'H330', 'H331'];
const exposureLabels = ['H372', 'H373'];

const ingestionLabels = {
    death: {
        certain: ['H300'],
        largeQuantity: ['H301', 'H304']
    },
    illness: {
        certain: ['H302'],
        largeQuantity: ['H303', 'H305']
    },
    distress: {
        certain: ['H335', 'H336'] // Need to check here!
    }
};

const inhalationLabels = {
    death: {
        certain: ['H330'],
        largeQuantity: ['H331']
    },
    illness: {
        certain: ['H332'],
        largeQuantity: ['H333']
    },
    distress: {
        certain: ['H334', 'H335', 'H336']
    }
};

const contactLabels = {
    death: {
        certain: ['H310'],
        largeQuantity: ['H311']
    },
    illness: {
        certain: ['H312'],
        largeQuantity: ['H313']
    },
    distress: {
        certain: ['H314', 'H315', 'H316', 'H317']
    }
};

const schedule_1 = [
    "107-44-8", "96-64-0", "77-81-6", "50782-69-9", "2625-76-5", "505-60-2", "63869-13-6",
    "3563-36-8", "63905-10-2", "142868-93-7", "142868-94-8", "63918-90-1", "63918-89-8",
    "541-25-3", "40334-69-8", "40334-70-1", "538-07-8", "51-75-2", "555-77-1",
    "35523-89-8", "9009-86-3", "676-99-3", "57856-11-8", "1445-76-7", "7040-57-5"
];

const schedule_2 = ["78-53-5", "382-21-8", "6581-06-2", "676-97-1", "756-79-6", "7784-34-1", "76-93-7", "1619-34-7", "111-48-8", "464-07-3"];

const schedule_3 = [
    "75-44-5", "506-77-4", "74-90-8", "76-06-2", "10025-87-3", "77419-12-2",
    "10026-13-8", "121-45-9", "122-52-1", "868-85-9", "762-04-9", "10025-67-9",
    "10545-99-0", "7719-09-7", "139-87-7", "105-59-9", "102-71-6"
];

const TICs = [
    "7664-41-7", "7784-42-1", "10294-34-5", "7637-07-2", "75-15-0", "7782-50-5", "19287-45-7", "75-21-8", "7782-41-4", "50-00-0", "10035-10-6", "7647-01-0", "74-90-8", "7664-39-3", "7783-0604",
    "7697-37-2", "75-44-5", "7719-12-2", "7446-09-5", "7664-93-9", "7783-82-6", "57-06-7", "7784-34-1", "7726-95-6", "13863-41-7", "7789-30-2", "7787-71-5", "353-50-4", "13637-63-3", "7790-91-2",
    "107-20-0", "79-04-9", "123-73-9", "506-77-4", "77-78-1", "101-68-8", "541-41-3", "2941-64-2", "993-43-1", "1066-50-8", "151-56-4", "77-47-4", "10034-85-2", "13463-40-6", "543-27-1",
    "108-23-6", "1795-48-8", "592-34-7", "111-36-4", "10102-43-9", "109-61-5", "56-38-2", "594-42-3", "17462-58-7", "1609-86-5", "78-00-2", "107-49-3", "75-74-1", "584-84-9", "91-08-7",
    "993-00-0", "79-22-1", "7803-52-3", "7803-51-2", "78-95-5", "7791-25-5", "7790-94-5", "7783-80-4", "7783-79-1", "7783-61-1", "7783-07-5", "7647-19-0", "76-02-8", "7550-45-0",
    "75-86-5", "7446-11-9", "74-93-1", "74-83-9", "674-82-8", "630-08-0", "624-83-9", "60-34-4", "540-73-8", "463-58-1", "354-32-5", "2937-50-0", "2699-79-8", "124-63-0", "111-88-6",
    "107-18-6", "107-13-1", "107-11-9", "107-02-8", "106-93-4", "10294-33-4", "10102-44-0", "10025-87-3"
];

const wassenarList = [
    "63918-90-1", "63918-89-8", "63905-10-2", "63869-13-6", "505-60-2", "3563-36-8", "2625-76-5", "142868-94-8", "142868-93-7", "555-77-1", "538-07-8",
    "51-75-2", "541-25-3", "40334-70-1", "40334-69-8", "5798-79-8", "578-94-9", "532-27-4", "5299-64-9", "2698-41-1", "257-07-8", "7040-57-5",
    "676-99-3", "57856-11-8", "1445-76-7", "96-64-0", "77-81-6", "50782-69-9", "107-44-8", "94-75-7", "93-76-5", "8015-35-8", "39277-47-9",
    "1692-85-9", "6581-06-2", "97096-78-1", "117412-28-9", "117907-74-1", "135285-90-4 ", "70247-32-4", "145250-81-3", "1630-08-6", "194486-77-6", "17215-44-0",
    "55510-04-8", "78644-90-3", "2691-41-0", "130256-72-3", "143850-71-9", "20062-22-0", "5213-49-0", "932-64-9", "38082-89-2", "121-82-4", "115029-35-1",
    "4000-16-2", "3058-38-6", "479-45-8", "135877-16-6", "97645-24-4", "55510-03-7", "229176-04-9", "19899-80-0", "130400-13-4", "1614-08-0", "30003-46-4",
    "70890-46-9", "25243-36-1", "119-27-7", "217464-38-5", "19836-28-3", "505-71-5", "7784-21-6", "17702-41-9", "18433-84-6", "19624-22-7", "302-01-2",
    "60-34-4", "540-73-8", "57-14-7", "7440-41-7", "7439-89-6", "7440-67-7", "7439-95-4", "7440-42-8", "12069-32-8", "637-12-7", "7429-90-5",
    "140456-78-6", "7790-98-9", "78246-06-7", "13465-08-2", "15588-62-2", "20773-28-8", "37836-27-4", "27978-54-7", "8007-58-7", "90683-29-7", "17607-20-4",
    "5108-69-0", "5917-61-3", "6659-60-5", "17003-79-1", "376-90-9", "143178-24-9", "69102-90-5", "17096-47-8", "85068-73-1", "82486-83-7", "82486-82-6",
    "85954-06-9", "27814-48-8", "84051-81-0", "53159-39-0", "3032-55-1", "62320-94-9", "17409-41-5", "125856-62-4", "37206-42-1", "31904-29-7", "1273-89-8",
    "1274-00-6", "1273-97-8", "1274-08-4", "93894-59-8", "1271-55-2", "1273-94-5", "20936-32-7", "14450-60-3", "68411-07-4", "19136-34-6", "15748-73-9",
    "12036-31-6", "57-39-6", "85068-72-0", "100-15-2", "7406-61-9", "103850-22-2", "110438-25-0 ", "7722-73-8", "7652-64-4", "18924-91-9", "71463-62-2",
    "75-55-8", "1317-60-8", "68412-45-3", "68412-46-4", "603-33-8", "90591-48-3", "78-71-7", "125735-38-8", "124782-15-6 ", "182763-60-6", "41378-98-7",
    "5409-42-7 ", "108-70-3", "3068-00-6"
];