import React, { useState, useEffect } from 'react';

import { getFirestore, getDoc, doc } from '@firebase/firestore';
import { Chart as ChartJS, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from "chart.js";
import { Bar } from "react-chartjs-2";
import { useSelector } from 'react-redux';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

export default function HIRARCStatsBarChart () {
    const { assignedLab } = useSelector(state => state.authentication.userDetails);

    const [HIRARCStats, setHIRARCStats] = useState({
        totalRisks: 0,
        highRisk: 0,
        medRisk: 0,
        lowRisk: 0,
        Explosive: 0, 
        Flammable: 0, 
        Oxidizing: 0, 
        Corrosive: 0, 
        Toxic: 0, 
        Harmful: 0, 
        'Compressed Gas': 0, 
        'Health Hazard': 0, 
        'Environmental Hazard': 0
    });

    const fetchHIRARCStats = async () => {
        try {
            const db = getFirestore();

            const stats = await getDoc(doc(db, 'HIRARC', assignedLab));

            setHIRARCStats(stats.data());
        } catch (err) {

        }
    };

    useEffect(_ => {
        fetchHIRARCStats(assignedLab);
    }, [assignedLab]);

    return (
        <div className='w-3/4 px-[15px] CSBorder CSBoxBorder !rounded-[5px]'>
            <Bar 
                data={{
                    labels: ['Explosive', 'Flammable', 'Oxidizing', 'Compressed Gas', 'Corrosive', 'Toxic', 'Harmful', 'Health Hazard', 'Environmental Hazard'],
                    datasets: [{
                        data: [
                            HIRARCStats.Explosive,
                            HIRARCStats.Flammable,
                            HIRARCStats.Oxidizing,
                            HIRARCStats['Compressed Gas'],
                            HIRARCStats.Corrosive,
                            HIRARCStats.Toxic,
                            HIRARCStats.Harmful,
                            HIRARCStats['Health Hazard'],
                            HIRARCStats['Environmental Hazard']
                        ],
                        backgroundColor: [
                            '#448f31', '#589a7b', '#86ae90', '#aec2ab', '#d0d6cc', '#d0dbb6', '#d9dd9d', '#e9dc82', '#ffd966'
                        ]
                    }]
                }}
                options={{
                    plugins: {
                        title: {
                            display: true,
                            align: 'start',
                            text: 'Hazard Distribution',
                        },
                        legend: {
                            display: false
                        }
                    }
                }}
            />
        </div>
    );
}