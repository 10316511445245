import React, { useState } from 'react';

import { Flex, Space, Typography, Button } from 'antd';
import { faEye, faPencil, faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate } from 'react-router-dom';
import useNotification from 'antd/es/notification/useNotification';
import { getFirestore, addDoc, doc, collection, Timestamp, updateDoc, increment } from '@firebase/firestore';
import { useSelector } from 'react-redux';

import HIRARCForm from './HIRARCForm';

export default function HIRARC () {
    const { assignedLab, uid } = useSelector(state => state.authentication.userDetails);

    const [items, setItems] = useState([]);
    const [update, setUpdate] = useState(null);
    const [edit, setEdit] = useState(false);
    const [api, context] = useNotification();
    const [loading, setLoading] = useState(false);
    
    const navigate = useNavigate();
    const headers = ['No.', 'Work Activities', 'Hazard', 'Effects', 'L', 'S', 'R'];
    const { state } = useLocation();

    const { isView, risks } = state === null ? {isView: false, risks: []} : state;

    const submitHIRARC = async () => {
        setLoading(true);

        try {
            const db = getFirestore();

            let high = 0;
            let med = 0;
            let low = 0;
            // The GHS category that will dictate the Hazard Category
            const GHSCategories = {
                'Explosive': 0, 
                'Flammable': 0, 
                'Oxidizing': 0, 
                'Compressed Gas': 0, 
                'Corrosive': 0, 
                'Toxic': 0, 
                'Harmful': 0, 
                'Health Hazard': 0, 
                'Environmental Hazard': 0
            };

            items.forEach((risk) => {
                const relativeRisk = risk.severity * risk.likelihood;
                if(relativeRisk >= 15)  {
                    high += 1;
                } else if(relativeRisk >= 5 || risk.severity === 4 || risk.likelihood === 4) {
                    med += 1
                } else {
                    low += 1;
                }

                GHSCategories[risk.hazard] += 1;
            });


            const newDoc = {
                highRisk: high,
                medRisk: med,
                lowRisk: low,
                risks: items,
                uid: uid,
                timestamp: Timestamp.now()
            };

            // Create an entry inside the log collection
            await addDoc(collection(db, 'HIRARC', assignedLab, 'analyses'), newDoc);

            // Update all the statistics for the HIRARC lab
            await updateDoc(doc(db, 'HIRARC', assignedLab), {
                totalRisks: increment(items.length),
                highRisk: increment(high),
                medRisk: increment(med),
                lowRisk: increment(low),
                'Explosive': increment(GHSCategories.Explosive), 
                'Flammable': increment(GHSCategories.Flammable), 
                'Oxidizing': increment(GHSCategories.Oxidizing), 
                'Compressed Gas': increment(GHSCategories['Compressed Gas']), 
                'Corrosive': increment(GHSCategories.Corrosive), 
                'Toxic': increment(GHSCategories.Toxic), 
                'Harmful': increment(GHSCategories.Harmful), 
                'Health Hazard': increment(GHSCategories['Health Hazard']), 
                'Environmental Hazard': increment(GHSCategories['Environmental Hazard'])
            });

            api.success({
                message: 'Success',
                description: 'HIRARC form submitted successfully.'
            });

            // navigate('/questionnaire');
            setItems([]);

        } catch (err) {
            api.error({
                message: 'Error',
                description: 'An error occured while creating the record, please try again.'
            });

        } finally {
            setLoading(false);
        }
    };

    // The main table of the HIRARC Form
    const mainPage = <div className="HIRARC">
            {context}
            <Typography className='oswald text-[32px] ps-[6px] font-bold'>HIRARC</Typography>

            {/* HIRARC Table (Needs to be custom) */}
            <article className='flex flex-col CSBoxShadow min-h-[15vh] border-black border-[1.5px] h-[65vh] w-[83vw] rounded-[10px] px-[30px] py-[10px]'>
                    {/* Header of the table */}
                <section className='CSHIRARCGrid pt-[15px] pb-[10px] gap-x-2'>
                    {headers.map((header) => <Typography className='font-bold oswald text-[24px]'> {header} </Typography>)}
                </section>
                <hr className='CSBorderColor border-[1px]'></hr>

                <div className='grow overflow-y-scroll mb-5'>
                    {/* Mapping the items */}
                    {(isView ? risks : items).map((item, i) => <div>
                        <section className='CSHIRARCGrid pt-[15px] pb-[10px] gap-x-2'>
                            <Typography.Text className='oswald text-[20px]' ellipsis> {i+1} </Typography.Text>
                            <Typography.Text className='oswald text-[20px]' ellipsis> {item.activity} </Typography.Text>
                            <Typography.Text className='oswald text-[20px]' ellipsis> {item.hazard} </Typography.Text>
                            <Typography.Text className='oswald text-[20px]' ellipsis> {item.effects[0]} </Typography.Text>
                            <Typography.Text className='oswald text-[20px]' ellipsis> {item.likelihood} </Typography.Text>
                            <Typography.Text className='oswald text-[20px]' ellipsis> {item.severity} </Typography.Text>
                            <Typography.Text className='oswald text-[20px]' ellipsis> {item.likelihood * item.severity} </Typography.Text>
                            <Space size='large'>
                                {/* Edit Button */}
                                <Flex justify='center' vertical className='w-[37px] h-[35px] rounded-[15px] p-1 text-center bg-[#93BEDF] cursor-pointer'>
                                    <FontAwesomeIcon icon={isView ? faEye : faPencil} color='white' size='xl' onClick={() => {
                                        setUpdate(i);
                                        setEdit(true);
                                    }} />
                                </Flex>
                                {/* Delete Item Button */}
                                {!isView && <Flex justify='center' vertical className='w-[37px] h-[35px] rounded-[15px] p-1 text-center bg-[#F74707] cursor-pointer'>
                                    <FontAwesomeIcon icon={faTrash} color='white' size='xl' onClick={() => {
                                        const newItems = [...items];
                                        newItems.splice(i, 1);
                                        setItems(newItems);
                                    }}/>
                                </Flex>}
                            </Space>
                        </section>
                        <hr className='CSBorderColor border-[1px]'></hr>
                    </div>)}
                </div>

                {/* Add items Div */}
                {!isView && <div className='cursor-pointer flex justify-center items-center bg-[#979797]/[.2] min-h-[5vh] border-dashed border-[1pt] border-[#646464] rounded-[5px]'
                    onClick={() => setEdit(true)}
                >
                    <span className='oswald text-[#646464] font-bold text-[18px]'>+ Add Item</span>
                </div>}

                {/* Action Buttons */}
                <Flex justify='flex-end' gap={15} className='mt-[20px] mb-[5px]'>
                    {/* Cancel Button */}
                    <Button type='primary' danger className='w-[125px] h-[42px] rounded-[15px] roboto font-bold text-[24px]' 
                        disabled={loading}
                        onClick={() => {
                            navigate('/questionnaire');
                        }}
                    >
                        Cancel
                    </Button>

                    {/* Save Button */}
                    {!isView && <Button
                        className='w-[125px] h-[42px] rounded-[15px] bg-[#42CA4E] text-white roboto font-bold text-[24px] hover:!bg-[#68cf70] hover:!text-white hover:!border-transparent'
                        disabled={items.length === 0 || loading}
                        onClick={submitHIRARC}
                    >
                        {loading ? <FontAwesomeIcon icon={faSpinner} spinPulse /> : 'Save'}
                    </Button>}
                </Flex>

            </article>
        </div>;

    // The edit page of the HIRARC Form
    const editPage = <HIRARCForm setEdit={setEdit} isView={isView} items={isView ? risks : items} setItems={setItems} update={update} setUpdate={setUpdate} />

    return (
        <div>
            <Typography className='oswald text-[48px] mb-[50px] font-bold'>Questionnaire</Typography>
            {edit ? editPage : mainPage}
        </div>
    )
}