import React, { useEffect, useState } from 'react';

import { Layout, Flex, Menu, Typography, Button, Avatar, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDoorOpen } from '@fortawesome/free-solid-svg-icons';
import { getAuth, signOut } from '@firebase/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { resetUserDetails } from '../providers/userSlice';
import { getStorage, ref, getDownloadURL } from '@firebase/storage';

const { Sider } = Layout;

export default function Sidebar(props) {
    const { listeners, setListeners } = props;
    const { email, accessLevel, verified, active, name, assignedLab, uid } = useSelector(state => state.authentication.userDetails ?? {});
    const originalLab = useSelector(state => state.authentication.originalLab ?? assignedLab);
    const [pfp, setPfp] = useState(null);
    
    const dispatch = useDispatch();
    // Some navigation methods
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(_ => {
        if(uid) {
            const storage = getStorage();
            getDownloadURL(ref(storage, `users/${originalLab}/${uid}/pfp.png`)).then(link => setPfp(link)).catch(e => null);
        }
    }, [uid]);

    return (
        <Sider width={props.collapsed ? '6vw' : '15vw'} className='overflow-hidden me-[70px] CSSideFixed' onMouseEnter={_ => props.setCollapsed(false)} onMouseLeave={_ => props.setCollapsed(true)}>
            <Flex vertical align='middle' wrap='nowrap' className='h-[100vh]'>
                
                {/* Icon */}
                <Typography
                    className='accent oswald font-bold text-[48px] self-center whitespace-nowrap'
                >
                    {props.collapsed ? 'C' : 'Chem'}
                    <span className='secondary-accent'>
                        {props.collapsed ? 'R' : 'RAS'}
                    </span>
                </Typography>

                {/* <Avatar  */}

                <Space className='self-center'>
                    { !props.collapsed && <Avatar src={pfp ?? '' } size='large' shape='square' /> }
                    <Typography className='oswald accent self-center text-[24px]'>{ props.collapsed ? '' : name } </Typography>
                </Space>
                <Typography className='oswald accent self-center text-[12px]'>{ props.collapsed ? '' : email } </Typography>
                <Typography className='oswald accent self-center text-[12px]'>{ props.collapsed ? '' : getUserClearance(accessLevel)} </Typography>

                {/* Divider */}
                <hr className='border-2 mt-2'/>

                {/* The navigation icons and text */}
                <Menu items={props.PageList.filter(page => accessLevel > 1 ? true : (!page.adminOnly && (active && verified)) || page.key === '/' || (!active && !verified && page.key === '/profile'))}
                    selectedKeys={[
                        location.pathname.substring(0, location.pathname.indexOf('/', 1)) === '' 
                        ? 
                        location.pathname
                        :
                        location.pathname.substring(0, location.pathname.indexOf('/', 1))
                    ]}
                    onSelect={({key}) => {
                        navigate(key);
                        scrollToTop();
                    }}
                    className='self-center'
                />

                {/* This is just there to push the button to the bottom of the screen */}
                <div className='grow'></div>
                
                {/* The button in question :O */}
                <Button className='CSDanger border-0 w-fit h-fit self-center mb-5 pt-0' onClick={() => {
                    navigate('/');
                    signOut(getAuth());
                    listeners.forEach(unsub => unsub());
                    setListeners([]);
                    dispatch(resetUserDetails());
                }}> 
                    {<FontAwesomeIcon icon={faDoorOpen} className={`text-[24px] ${props.collapsed ? '' : 'me-2 p-1'}`} />} 
                    {props.collapsed ? null : <span className='oswald text-[24px]'>Sign Out</span>} 
                </Button>
            
            </Flex>
        </Sider>
    );
}

function scrollToTop() {
    document.getElementById('Main').scrollTo({top: 0, left: 0, behavior: 'smooth'});
}

function getUserClearance (lvl) {
    switch (lvl) {
        case 1:
            return 'Staff Member';
        case 2:
            return 'Lab Owner';
        case 3:
            return 'Administrator';
        default:
            return 'Guest';
    }
}