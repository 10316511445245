import React, { useState, useEffect } from 'react';

import { Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { collection, getDoc, getDocs, getFirestore, doc, query, limit, orderBy, Timestamp } from '@firebase/firestore';
import { useSelector } from 'react-redux';
import EmptyTableMessage from '../EmptyTableMessage';
import { faScroll } from '@fortawesome/free-solid-svg-icons';

export default function HIRARCHistoryTable() {
    const [HIRARCHistory, setHIRARCHistory] = useState([{
        timestamp: Timestamp.now()
    }]);

    const { assignedLab } = useSelector(state => state.authentication.userDetails);
    const navigate = useNavigate();
    const HIRARCHeaders = ['User', 'Date', 'Total Risks', 'High/Med/Low'];

    const fetchHIRARC = async () => {
        try {
            const db = getFirestore();

            const HIRARCSnap = await getDocs(query(collection(db, 'HIRARC', assignedLab, 'analyses')), orderBy('timestamp', 'asc'), limit(5));

            const HIRARCData = await Promise.all(HIRARCSnap.docs.map(async (item) => {
                return {
                    ...item.data(),
                    email: (await getDoc(doc(db, 'users', item.data().uid))).data().email
            }}));

            setHIRARCHistory(HIRARCData);
        } catch (err) {

        }
    }

    useEffect(_ => {
        Promise.all([fetchHIRARC(assignedLab)])
    }, [assignedLab]);

    return (
        <div className="HIRARCHistoryTable">
            <Typography className='font-bold text-[26px] oswald'>HIRARC</Typography>
            <div className='CSBorder CSBoxShadow !rounded-[5px]'>
                <div className="CSHIRARCDashGrid my-[5px]">
                    {HIRARCHeaders.map(header => <Typography className='oswald text-[20px] font-bold'>{header}</Typography>)}
                </div>
                <hr className='border-black'/>
                <div>
                    {HIRARCHistory.length === 0 ? <EmptyTableMessage message='No analyses performed yet' icon={faScroll} /> : HIRARCHistory.map(item => 
                        <div className='CSHIRARCDashGridContent p-1' onClick={_ => {
                            navigate('/questionnaire/HIRARC', {state: {
                                isView: true, 
                                risks: item.risks
                            }});
                            scrollToTop();
                        }}>
                            <Typography className={`roboto text-[16px] text-ellipsis overflow-hidden whitespace-nowrap`}>{item.email}</Typography>
                            <Typography className={`roboto text-[16px]`}>{item.timestamp.toDate().toLocaleDateString()}</Typography>
                            <Typography className={`roboto text-[16px]`}>{item.highRisk + item.medRisk + item.lowRisk}</Typography>
                            <Typography className={`roboto text-[16px]`}>{`${item.highRisk}/ ${item.medRisk}/ ${item.lowRisk}`}</Typography>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

function scrollToTop() {
    document.getElementById('Main').scrollTo({top: 0, left: 0, behavior: 'smooth'});
}