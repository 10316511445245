import React, { useState } from 'react';

import { Flex } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faCircleMinus } from '@fortawesome/free-solid-svg-icons';

export default function EffectMenu (props) {
    const [selectedItem, setSelectedItem] = useState(null);

    return (
        <Flex className='h-full'>
            <div className={`${props.isView ? 'bg-[rgba(0,0,0,0.04)]' : null} CSBoxShadow max-h-40 h-3/4 w-[99%] rounded-[5px] border-black border-[1px] flex flex-col overflow-y-scroll`}>
                {props.items.map((item, pos) => {
                    return <div className={`${pos === selectedItem ? 'bg-[rgba(147,190,223,0.2)]' : null} roboto text-[20px] px-3 py-1`} onClick={props.isView ? null : _ => setSelectedItem(pos)}>
                        <span contentEditable={pos === selectedItem && !props.isView} suppressContentEditableWarning className={`m-1 ${props.isView ? 'text-[rgba(0,0,0,0.25)]' : null}`}  onInput={val => {
                            const newItems = props.items;
                            newItems[pos] = val.target.textContent;
                            props.setItems(newItems);
                        }}
                        >{item}</span>
                        <hr />
                    </div>
                })}
            </div>
            <Flex vertical={true} justify='flex-end' className='h-3/4 ms-3'>
                <FontAwesomeIcon icon={faCirclePlus} className={`h-[40px] w-[40px] pb-[10px] ${props.isView ? 'accent cursor-not-allowed' : 'secondary cursor-pointer'}`} 
                    onClick={props.isView ? null : () => {
                        setSelectedItem(null);
                        const newItems = [...props.items];
                        newItems.push('New Item');
                        props.setItems(newItems);
                    }}
                />
                <FontAwesomeIcon icon={faCircleMinus} className={`h-[40px] w-[40px] ${selectedItem === null || props.items.length === 1 ? 'accent cursor-not-allowed' : 'tertiary cursor-pointer'}`}
                    onClick={props.isView ? null : _ => {
                        if (selectedItem === null || props.items.length === 1) {
                            return;
                        } else {
                            const newArr = [...props.items];
                            newArr.splice(selectedItem, 1);
                            props.setItems(newArr);
                            setSelectedItem(null);
                        }
                    }}
                />
            </Flex>
        </Flex>
    )
}