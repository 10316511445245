import React, { useState } from 'react';

import { Typography, Flex } from 'antd';

import PendingUserTable from './PendingUserTable';
import StaffTable from './StaffTable';
import LabSelector from '../LabSelector';

export default function Users () {
    // Pass this to the two tables to allow one to trigger the other upon request
    // Works like a simple switch due to the way useEffect dependancies work
    const [triggerStaffRender, setTriggerStaffRender] = useState(false);

    return (
        <div className='Users w-[82vw]'>
            <Flex justify='space-between' align='baseline'>
                <Typography className='oswald text-[48px] font-bold mb-[37px]'>Users</Typography>
                {/* <Typography className='oswald text-[36px] font-semibold'>Lab Code: {' ' + assignedLab}</Typography> */}
                <LabSelector />
            </Flex>

            <article>
                <PendingUserTable setTriggerStaffRender={setTriggerStaffRender} triggerStaffRender={triggerStaffRender} />
                
                <StaffTable triggerStaffRender={triggerStaffRender} />
            </article>
        </div>
    );
}