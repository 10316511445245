import React, { useState, useEffect } from 'react';

import { Typography, notification } from 'antd';
import { getFirestore, collection, getDocs, doc, getDoc, query, where } from '@firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

import ChemSAMForm from './ChemSAMForm';
import { ChemSAMJSON } from '../../../JSON/ChemSAM';

export default function ChemSAM () {
    const [currentPage, setCurrentPage] = useState(0);
    const [pages, setPages] = useState(ChemSAMJSON);
    const [loading, setLoading] = useState(false);
    const [Chemicals, setChemicals] = useState({});
    const [Labs, setLabs] = useState({});
    const [api, context] = notification.useNotification();

    const { assignedLab, accessLevel } = useSelector(state => state.authentication.userDetails);

    const setupDetails = async () => {
        try {
            const db = getFirestore();

            // Collect a list of the labs first
            let labs = null;
            if(accessLevel === 3) {
                labs = (await getDocs(collection(db, 'labs'))).docs;
            } else {
                labs = [await getDoc(doc(db, 'labs', assignedLab))];
            }

            // Set the lab details
            const labMap = {};
            // Get and set the labs
            labs.forEach(lab => {
                labMap[lab.id] = lab.data().security;
            });
            setLabs(labMap);
            
        } catch (err) {
            api.error({message: 'Failed to fetch labs!', duration: 3});
        } finally {
            setLoading(false);
        }
    }

    const fetchChemicals = async (lab) => {
        try {
            const db = getFirestore();

            // Get the chemicals
            const chemicalsSnapshot = await getDocs(query(collection(db, 'Chemicals', lab, 'chemicals'), where('hasSDS', '==', true)));
            const chemicalMap = {};
            
            // Set the chemicals
            chemicalsSnapshot.forEach(chem => {
                chemicalMap[chem.id] = chem.data();
            });
            setChemicals(chemicalMap);
        } catch (err) {
            api.error({message: 'Failed to fetch chemicals!', duration: 3});
        }
    }

    useEffect(() => {
        Promise.all([setupDetails()]);
    }, [])

    return (
        <div>
            {context}
            <Typography className='oswald text-[48px] font-bold mb-[37px]'>Questionnaire</Typography>

            {
                loading ?
                <FontAwesomeIcon icon={faSpinner} spinPulse/> 
                :
                <ChemSAMForm data={pages[currentPage]} currentPage={currentPage} setCurrentPage={setCurrentPage} pages={pages} setPages={setPages} Chemicals={Chemicals} Labs={Labs} fetchChemicals={fetchChemicals} notificationAPI={api}/>
            }
        </div>
    );
}