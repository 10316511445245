export const ChemSAMJSON = [
    {
        key: 'setup',
        section: 'Define Chemical Assets',
        title: 'Step 0 - Define Chemical and Laboratory',
        theftMult: 0,
        populatedMult: 0,
        industrialMult: 0,
        outsiderMult: 0,
        insiderMult: 0,
        questions: [
            {
                name: 'lab',
                title: 'Lab Code',
                type: 'select'
            },
            {
                name: 'cas',
                title: 'CAS No.',
                type: 'select',
            }
        ],
        data: {},
    },
    {
        dictatedByChem: true,
        key: 'likelihood',
        section: 'Define Chemical Assets',
        title: 'Step 1a - Likelihood of Chemical Being Targeted',
        questions: [
            {
                name: 'quantity',
                title: 'Quantity of Chemical',
                type: 'radio button',
                theftMult: 0.5,
                populatedMult: 0.4,
                industrialMult: 0.4,
                outsiderMult: 0,
                insiderMult: 0,
                options: [
                    {title: 'Insufficient to cause any harm', value: 0},
                    {title: 'Sufficient to cause serious harm', value: 4}
                ]
            },
            {
                name: 'stealable',
                title: 'Is it possible to steal this chemical?',
                type: 'radio button',
                theftMult: 0.5,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0,
                insiderMult: 0,
                options: [
                    {title: 'Can\'t be moved', value: 0},
                    {title: 'Portable with special equipment', value: 1},
                    {title: 'Easily portable', value: 4},
                ]
            },
            {
                name: 'opcw',
                title: 'Is the chemical on the OPCW list?',
                type: 'radio button',
                theftMult: 0.2,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0,
                insiderMult: 0,
                options: [
                    {title: 'No', value: 0},
                    {title: 'No, toxic inhalation hazard', value: 1},
                    {title: 'No, nationally regualted', value: 2},
                    {title: 'Yes, schedule 2 or 3 chemical', value: 3},
                    {title: 'Yes, schedule 1', value: 4},
                ]
            },
            {
                name: 'toxic',
                title: 'Is the chemical toxic?',
                type: 'radio button',
                theftMult: 0.3,
                populatedMult: 0.3,
                industrialMult: 0.1,
                outsiderMult: 0,
                insiderMult: 0,
                options: [
                    {title: 'No', value: 0},
                    {title: 'Yes', value: 4}
                ]
            },
            {
                name: 'flammable',
                title: 'Is the chemical flammable?',
                type: 'radio button',
                theftMult: 0,
                populatedMult: 0.1,
                industrialMult: 0.3,
                outsiderMult: 0,
                insiderMult: 0,
                options: [
                    {title: 'No', value: 0},
                    {title: 'Yes', value: 4}
                ]
            },
            {
                name: 'volatile',
                title: 'Volatility',
                type: 'radio button',
                theftMult: 0.5,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0,
                insiderMult: 0,
                options: [
                    {title: 'Solid, difficult to convert', value: 0},
                    {title: 'Solid, easy to convert', value: 1},
                    {title: 'Liquid, minimally volatile', value: 2},
                    {title: 'Liquid, volatile', value: 3},
                    {title: 'Gaseous', value: 4},
                ]
            },
            {
                name: 'isSolid',
                title: 'Chemical is stored as a solid in the facility?',
                type: 'radio button',
                theftMult: 0,
                populatedMult: 0.01,
                industrialMult: 0.01,
                outsiderMult: 0,
                insiderMult: 0,
                options: [
                    {title: 'No', value:0},
                    {title: 'Yes', value:4}
                ]
            },
            {
                name: 'isLiquid',
                title: 'Chemical is stored as a liquid in the facility?',
                type: 'radio button',
                theftMult: 0,
                populatedMult: 0.08,
                industrialMult: 0.08,
                outsiderMult: 0,
                insiderMult: 0,
                options: [
                    {title: 'No', value:0},
                    {title: 'Yes', value:4},
                ]
            },
            {
                name: 'isGas',
                title: 'Chemical is stored as a gas in the facility?',
                type: 'radio button',
                theftMult: 0,
                populatedMult: 0.11,
                industrialMult: 0.11,
                outsiderMult: 0,
                insiderMult: 0,
                options: [
                    {title: 'No', value:0},
                    {title: 'Yes', value:4},
                ]
            }
        ],
        data: {}
    },
    {
      dictatedByChem: true,
      key: 'consequence',
      section: 'Define Chemical Assets',
      title: 'Step 1b - Human Health Consqequence of Release',
      questions: [
          {
              name: 'inhalationIllness',
              title: 'Can inhalation cause illness?',
              type: 'radio button',
              theftMult: 0.45,
              populatedMult: 0.47,
              industrialMult: 0.23,
              outsiderMult: 0,
              insiderMult: 0,
              options: [
                  {title: 'No', value: 0},
                  {title: 'Yes, if large volume', value: 3},
                  {title: 'Yes', value: 4}
              ]
          },
          {
              name: 'inhalationDeath',
              title: 'Can inhalation cause rapid death?',
              type: 'radio button',
              theftMult: 0.65,
              populatedMult: 0.65,
              industrialMult: 0.65,
              outsiderMult: 0,
              insiderMult: 0,
              options: [
                  {title: 'No', value: 0},
                  {title: 'Rare, but possible', value: 2},
                  {title: 'Yes', value: 4}
              ]
          },
          {
              name: 'inhalationDistress',
              title: 'Can inhalation cause respiratory distress?',
              type: 'radio button',
              theftMult: 0.35,
              populatedMult: 0.35,
              industrialMult: 0.35,
              outsiderMult: 0,
              insiderMult: 0,
              options: [
                  {title: 'No', value: 0},
                  {title: 'Yes', value: 4}
              ]
          },
          {
              name: 'ingestionIllness',
              title: 'Can ingestion cause illness?',
              type: 'radio button',
              theftMult: 0.08,
              populatedMult: 0.08,
              industrialMult: 0.14,
              outsiderMult: 0,
              insiderMult: 0,
              options: [
                  {title: 'No', value: 0},
                  {title: 'Yes, if large volume', value: 3},
                  {title: 'Yes', value: 4}
              ]
          },
          {
              name: 'ingestionDeath',
              title: 'Can ingestion cause rapid death?',
              type: 'radio button',
              theftMult: 0.65,
              populatedMult: 0.65,
              industrialMult: 0.65,
              outsiderMult: 0,
              insiderMult: 0,
              options: [
                  {title: 'No', value: 0},
                  {title: 'Rare, but possible', value: 2},
                  {title: 'Yes', value: 4}
              ]
          },
          {
              name: 'ingestionDistress',
              title: 'Can ingestion cause illness (vomiting, ulcers, etc.)?',
              type: 'radio button',
              theftMult: 0.35,
              populatedMult: 0.35,
              industrialMult: 0.35,
              outsiderMult: 0,
              insiderMult: 0,
              options: [
                  {title: 'No', value: 0},
                  {title: 'Yes', value: 4}
              ]
          },
          {
              name: 'physicalIllness',
              title: 'Can physical contact cause illness?',
              type: 'radio button',
              theftMult: 0.2,
              populatedMult: 0.21,
              industrialMult: 0.34,
              outsiderMult: 0,
              insiderMult: 0,
              options: [
                  {title: 'No', value: 0},
                  {title: 'Yes, if large volume', value: 3},
                  {title: 'Yes', value: 4}
              ]
          },
          {
              name: 'physicalDeath',
              title: 'Can physical contact cause death?',
              type: 'radio button',
              theftMult: 0.65,
              populatedMult: 0.65,
              industrialMult: 0.65,
              outsiderMult: 0,
              insiderMult: 0,
              options: [
                  {title: 'No', value: 0},
                  {title: 'Rare, but possible', value: 2},
                  {title: 'Yes', value: 4}
              ]
          },
          {
              name: 'physicalDistress',
              title: 'Can physical contact cause illness (burns, blisters, etc.)?',
              type: 'radio button',
              theftMult: 0.35,
              populatedMult: 0.35,
              industrialMult: 0.35,
              outsiderMult: 0,
              insiderMult: 0,
              options: [
                  {title: 'No', value: 0},
                  {title: 'Yes', value: 4}
              ]
          },
      ],
      data: {}
    },
    {
        dictatedByChem: true,
        key: 'impact',
        section: 'Define Chemical Assets',
        title: 'Step 1c - Socio-Economic Impacts of Release',
        questions: [
            {
                name: 'hazardous',
                title: 'Is chronic exposure hazardous?',
                type: 'radio button',
                theftMult: 0.03,
                populatedMult: 0.07,
                industrialMult: 0.11,
                outsiderMult: 0,
                insiderMult: 0,
                options: [
                    {title: 'No', value: 0},
                    {title: 'Yes', value: 4}
                ]
            },
            {
                name: 'weaponization',
                title: 'Has this chemical been used a weapon historically?',
                type: 'radio button',
                theftMult: 0.15,
                populatedMult: 0.01,
                industrialMult: 0.04,
                outsiderMult: 0,
                insiderMult: 0,
                options: [
                    {title: 'No', value: 0},
                    {title: 'Yes', value: 4}
                ]
            },
            {
                name: 'waterPersist',
                title: 'How persistent is the chemical in water?',
                type: 'radio',
                theftMult: 0.04,
                populatedMult: 0.06,
                industrialMult: 0.05,
                outsiderMult: 0,
                insiderMult: 0,
                options: [
                    {title: 'Not Persistent', value: 0},
                    {title: 'Persistent, but removable with extensive filtering', value: 3},
                    {title: 'Persistent, water becomes impossible to decontaminate', value: 4}
                ]
            },
            {
                name: 'soilPersist',
                title: 'How persistent is the chemical in soil/solids?',
                type: 'radio',
                theftMult: 0.05,
                populatedMult: 0.08,
                industrialMult: 0.09,
                outsiderMult: 0,
                insiderMult: 0,
                options: [
                    {title: 'Not Persistent', value: 0},
                    {title: 'Persistent, but removable with extensive filtering', value: 3},
                    {title: 'Persistent, soil becomes impossible to decontaminate', value: 4}
                ]
            }
        ],
        data: {}
    },
    {
        dictatedByChem: false,
        key: 'receiving',
        section: 'Chemical Security Assessment',
        title: 'Step 2a - Chemical Receiving',
        questions: [
            {
                name: 'source',
                title: 'Source and method of procuring chemicals:',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0,
                insiderMult: 0.001,
                options: [
                    {title: 'Licensed distributors with documentation included', value: 0},
                    {title: 'Unlicensed distributor with documentation included (could be unofficial)', value: 2},
                    {title: 'Licensed distributor with no documentation included', value: 3},
                    {title: 'Unlicensed distributor with no documentation', value: 4}
                ]
            },
            {
                name: 'smallVerify',
                title: 'Are small volumes of chemicals verified upon receipt?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0,
                insiderMult: 0.001,
                options: [
                    {title: 'Yes/ Don\'t receive in this volume', value: 0},
                    {title: 'No', value: 4}
                ]
            },
            {
                name: 'smallInventory',
                title: 'Are small volumes of chemicals inventoried upon receipt?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0,
                insiderMult: 0.0015,
                options: [
                    {title: 'Yes/ Don\'t receive in this volume', value: 0},
                    {title: 'No', value: 4}
                ]
            },
            {
                name: 'smallSecured',
                title: 'Are small volumes of chemicals physically secured until transported to storage?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.01,
                insiderMult: 0.025,
                options: [
                    {title: 'Yes', value: 0},
                    {title: 'No', value: 4}
                ]
            },
            {
                name: 'bulkVerify',
                title: 'Are bulk chemicals verified upon receipt?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0,
                insiderMult: 0.001,
                options: [
                    {title: 'Yes/ Don\'t receive in this volume', value: 0},
                    {title: 'No', value: 4}
                ]
            },
            {
                name: 'bulkInventory',
                title: 'Are bulk chemicals inventoried upon receipt?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0,
                insiderMult: 0.0015,
                options: [
                    {title: 'Yes/ Don\'t receive in this volume', value: 0},
                    {title: 'No', value: 4}
                ]
            },
            {
                name: 'bulkSecured',
                title: 'Are bulk chemicals physically secured until transported to storage?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0,
                insiderMult: 0.001,
                options: [
                    {title: 'Yes', value: 0},
                    {title: 'No', value: 4}
                ]
            },
            {
                name: 'largeVerify',
                title: 'Are large volumes of chemicals verified upon receipt?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.01,
                insiderMult: 0.02,
                options: [
                    {title: 'Yes/ Don\'t receive in this volume', value: 0},
                    {title: 'No', value: 4}
                ]
            },
            {
                name: 'largeInventory',
                title: 'Are large volumes of chemicals inventoried upon receipt?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0,
                insiderMult: 0.001,
                options: [
                    {title: 'Yes/ Don\'t receive in this volume', value: 0},
                    {title: 'No', value: 4}
                ]
            },
            {
                name: 'largeSecured',
                title: 'Are large volumes of chemicals physically secured until transported to storage?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.01,
                insiderMult: 0.02,
                options: [
                    {title: 'Yes', value: 0},
                    {title: 'No', value: 4}
                ]
            },
            {
                name: 'pipelinesSecured',
                title: 'Are pipelines physically secured upon entry to the facility?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.01,
                insiderMult: 0.001,
                options: [
                    {title: 'Yes/ Don\'t receive in this volume', value: 0},
                    {title: 'No', value: 4}
                ]
            }
        ],
        data: {}
    },
    {
        dictatedByChem: false,
        key: 'materialControl',
        section: 'Chemical Security Assessment',
        title: 'Step 2b - Material Control and Accountability',
        questions: [
            {
                name: 'inventory',
                title: 'Are all chemicals inventoried?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.015,
                insiderMult: 0.05,
                options: [
                    {title: 'All chemicals are inventoried and audited regularly', value: 0},
                    {title: 'All chemicals are inventoried but inventory is not cleaned or administered', value: 1},
                    {title: 'All chemicals are inventoried but not audited', value: 2},
                    {title: 'No inventory exists (paper or electronic)', value: 4}
                ]
            },
            {
                name: 'controlLevel',
                title: 'What is the level of control on chemicals?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.015,
                insiderMult: 0.05,
                options: [
                    {title: 'Quantities are verified before, during, and after use', value: 0},
                    {title: 'Quantities are verified before and after use only', value: 1},
                    {title: 'Quantities are verified before use only', value: 2},
                    {title: 'Quantities are not verified', value: 4}
                ]
            },
            {
                name: 'controlProcedures',
                title: 'Are there clearly defined procedures for material control?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.015,
                insiderMult: 0.05,
                options: [
                    {title: 'Documented, approved, and regularly reviewed', value: 0},
                    {title: 'Documented and approved but not reviewed regularly', value: 1},
                    {title: 'Documented but neither approved not reviewed', value: 2},
                    {title: 'Not documented', value: 3},
                    {title: 'No', value: 4}
                ]
            },
            {
                name: 'hazardLabels',
                title: 'What hazard labels are in use?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.015,
                insiderMult: 0.05,
                options: [
                    {title: 'In use but chemical names are only listed inside the lab', value: 0},
                    {title: 'Not in use', value: 2},
                    {title: 'In use and chemical names are visible from outside the lab', value: 4}
                ]
            }
        ],
        data: {}
    },
    {
        dictatedByChem: false,
        key: 'reliability',
        section: 'Chemical Security Assessment',
        title: 'Step 2c - Personnel Reliability',
        questions: [
            {
                name: 'directVet',
                title: 'How are personnel vetted prior to allowing access to chemicals?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0,
                insiderMult: 0.3,
                options: [
                    {title: 'Verification of credentials, references, criminal history, etc.', value: 0},
                    {title: 'Verification of credentials, and/or references', value: 2},
                    {title: 'No vetting', value: 4}
                ]
            },
            {
                name: 'indirectVet',
                title: 'How are personnel who do not have direct access to chemicals vetted?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0,
                insiderMult: 0.05,
                options: [
                    {title: 'Verification of credentials, references, criminal history, etc.', value: 0},
                    {title: 'Verification of credentials, and/or references', value: 2},
                    {title: 'No vetting', value: 4}
                ]
            },
            {
                name: 'escort',
                title: 'How are people with restriced access escorted?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0,
                insiderMult: 0.05,
                options: [
                    {title: 'Escorted by vetted personnel', value: 0},
                    {title: 'Escorted by unvetted personnel', value: 3},
                    {title: 'Unescorted acceess', value: 4}
                ]
            },
            {
                name: 'badges',
                title: 'Are badges worn?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0,
                insiderMult: 0.02,
                options: [
                    {title: 'At all times', value: 0},
                    {title: 'Yes, but not enforced', value: 2},
                    {title: 'For entry only', value: 3},
                    {title: 'No', value: 4}
                ]
            },
            {
                name: 'returnProcedure',
                title: 'Are there procedures for returning badges, keys, and other secured items?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0,
                insiderMult: 0.01,
                options: [
                    {title: 'Yes', value: 0},
                    {title: 'Yes, not enforced', value: 1},
                    {title: 'No', value: 4}
                ]
            },
            {
                name: 'badgeLevel',
                title: 'Do badges indicate access level?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0,
                insiderMult: 0.01,
                options: [
                    {title: 'No', value: 0},
                    {title: 'Yes', value: 4}
                ]
            },
            {
                name: 'badgePhoto',
                title: 'Do badges include a photo of the wearer?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0,
                insiderMult: 0.01,
                options: [
                    {title: 'Yes, with validity', value: 0},
                    {title: 'Only validity', value: 1},
                    {title: 'Only photo', value: 2},
                    {title: 'No', value: 4}
                ]
            },
            {
                name: 'securityTraining',
                title: 'What type of security training is provided?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0,
                insiderMult: 0.03,
                options: [
                    {title: 'Security training and awarness provided to all personnel and is documented', value: 0},
                    {title: 'All personnel are trained regarding security procedures', value: 1},
                    {title: 'No formal procedure but all are instructed in general security awareness', value: 2},
                    {title: 'No formal training but encouraged to attend security training events', value: 3},
                    {title: 'No training provided', value: 4}
                ]
            }
        ],
        data: {}
    },
    {
        dictatedByChem: false,
        key: 'physicalSec',
        section: 'Chemical Security Assessment',
        title: 'Step 2d - Physical Security of Chemicals while at the Facility',
        questions: [
            {
                name: 'perimeterSmallCar',
                title: 'Does the perimeter prevent small or medium sized cards from driving?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.01,
                insiderMult: 0.0015,
                options: [
                    {title: 'Perimeter is defined and prevents cards', value: 0},
                    {title: 'Perimeter is defined but does not prevent cars', value: 2},
                    {title: 'No defined perimeter', value: 4}
                ]
            },
            {
                name: 'perimeterPerson',
                title: 'Does the perimeter prevent a person from entering?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.01,
                insiderMult: 0.0015,
                options: [
                    {title: 'Perimeter defined and prevents undetected entry', value: 0},
                    {title: 'Perimeter defined but does not prevent people', value: 2},
                    {title: 'No defined perimeter', value: 4}
                ]
            },
            {
                name: 'perimeterBreach',
                title: 'Are there any measures to detect someone having breached the perimeter?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.02,
                insiderMult: 0.004,
                options: [
                    {title: 'Present at all points and can determine source', value: 0},
                    {title: 'Present at all points but unable to determine source', value: 2},
                    {title: 'Only at entry and exit points', value: 3},
                    {title: 'No measures to detect entry', value: 4}
                ]
            },
            {
                name: 'chemicalsVehicles',
                title: 'Are chemicals stored outside of buildings where vechiles can pass by?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.03,
                insiderMult: 0.005,
                options: [
                    {title: 'Perimeter exists and prevents vehicles', value: 0},
                    {title: 'Perimeter exist but does not prevent vehicles', value: 2},
                    {title: 'No defined perimeter', value: 4}
                ]
            },
            {
                name: 'chemicalsPeople',
                title: 'Are chemicals stored outside of buildings where people can pass by?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.03,
                insiderMult: 0.003,
                options: [
                    {title: 'Perimeter exists and prevents undetected entry', value: 0},
                    {title: 'Perimeter exist but does not prevent people', value: 2},
                    {title: 'No defined perimeter', value: 4}
                ]
            },
            {
                name: 'perimeterInternal',
                title: 'Are there measures to detect an internal breach of perimeter?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.01,
                insiderMult: 0.005,
                options: [
                    {title: 'Present at all points and can determine source', value: 0},
                    {title: 'Present at all points but unable to determine source', value: 2},
                    {title: 'Only at entry and exit points', value: 3},
                    {title: 'No measures to detect entry', value: 4}
                ]
            },
            {
                name: 'chemicalBuildingLimit',
                title: 'Do buildings housing chemicals limit access through control system?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.02,
                insiderMult: 0.006,
                options: [
                    {title: 'Yes, either electronic or with documented key control', value: 0},
                    {title: 'Manual access but doors locked with a common key', value: 1},
                    {title: 'Manual access but locked only during after hours', value: 2},
                    {title: 'No', value: 4}
                ]
            },
            {
                name: 'chemicalRoomLimit',
                title: 'Do rooms housing chemicals limit access through control system?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.04,
                insiderMult: 0.006,
                options: [
                    {title: 'Yes, either electronic or with documented key control', value: 0},
                    {title: 'Manual access but doors locked with a common key', value: 1},
                    {title: 'Manual access but locked only during after hours', value: 2},
                    {title: 'No', value: 4}
                ]
            },
            {
                name: 'chemicalCabinetLimit',
                title: 'Do chemical storage cabinetss limit access through control system?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.04,
                insiderMult: 0.01,
                options: [
                    {title: 'Yes, either electronic or with documented key control', value: 0},
                    {title: 'Manual access but doors locked with a common key', value: 1},
                    {title: 'Manual access but locked only during after hours', value: 2},
                    {title: 'No', value: 4}
                ]
            },
            {
                name: 'intrusionDetection',
                title: 'Do intrusion detection systems exist in areas where chemicals are stored?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.07,
                insiderMult: 0.01,
                options: [
                    {title: 'Yes, and capable of determining source', value: 0},
                    {title: 'Audible and/or visual alarm but incapable of determining source', value: 2},
                    {title: 'Audible alarms only', value: 3},
                    {title: 'Unable to detect unauthorized entry', value: 4}
                ]
            },
            {
                name: 'storageRoomAccess',
                title: 'Do chemical storage allow access through only windows and/or doors?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.05,
                insiderMult: 0.01,
                options: [
                    {title: 'Yes, and entry from other points would require significant time and effort', value: 0},
                    {title: 'Yes, but entry through other points is possible without much effort', value: 2},
                    {title: 'No', value: 4}
                ]
            },
            {
                name: 'accessSecured',
                title: 'Are all doors and windows secured?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.05,
                insiderMult: 0.01,
                options: [
                    {title: 'Yes', value: 0},
                    {title: 'Yes but are used for ventilation', value: 3},
                    {title: 'No', value: 4}
                ]
            },
            {
                name: 'accessAlarm',
                title: 'Are all entry points alarmed?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.005,
                insiderMult: 0.01,
                options: [
                    {title: 'Yes', value: 0},
                    {title: 'No', value: 4}
                ]
            },
            {
                name: 'chemicalRouteControl',
                title: 'Are all areas in which chemicals are routed controlled?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.07,
                insiderMult: 0.01,
                options: [
                    {title: 'Yes, either electronic or with documented key control and only certain personnel allowed', value: 0},
                    {title: 'Manual access but locked only during after hours but all personnel allowed', value: 1},
                    {title: 'Manual access but doors locked with a common key', value: 2},
                    {title: 'Manual access but locked only during after hours', value: 3},
                    {title: 'No', value: 4}
                ]
            },
            {
                name: 'chemicalRouteStructure',
                title: 'Are all areas in which chemicals are routed structurally sound?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.05,
                insiderMult: 0.01,
                options: [
                    {title: 'Yes, and entry from other points would require significant time and effort', value: 0},
                    {title: 'Yes, but entry through other points is possible without much effort', value: 2},
                    {title: 'No', value: 4}
                ]
            },
            {
                name: 'chemicalRouteAlarm',
                title: 'Are all areas in which chemicals are routed alarmed?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.01,
                insiderMult: 0.01,
                options: [
                    {title: 'Yes', value: 0},
                    {title: 'No', value: 4}
                ]
            },
            {
                name: 'alarmTied',
                title: 'Are alarms directly tied to detection system?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.01,
                insiderMult: 0.001,
                options: [
                    {title: 'Yes, to on-site emergency team', value: 0},
                    {title: 'Yes, to external team with no more than 10 min response time', value: 1},
                    {title: 'Yes, to any on site personnel', value: 2},
                    {title: 'Yes, to external team with more than 10 min response time', value: 3},
                    {title: 'No', value: 4}
                ]
            },
            {
                name: 'alarmResponse',
                title: 'Is there an immediate response to the alarms?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.02,
                insiderMult: 0.001,
                options: [
                    {title: 'Yes, and delay is no longer than 10 minutes', value: 0},
                    {title: 'Yes, but delay is more than 10 minutes', value: 3},
                    {title: 'No', value: 4}
                ]
            },
            {
                name: 'alarmAssessment',
                title: 'How are alarms assessed?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.01,
                insiderMult: 0.002,
                options: [
                    {title: 'Camera records before and after alarm', value: 0},
                    {title: 'Assessment done manually', value: 2},
                    {title: 'Camera but only live feed', value: 3},
                    {title: 'No assessment', value: 4}
                ]
            },
            {
                name: 'onsiteGuard',
                title: 'Is there an on-site guard force?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.01,
                insiderMult: 0.001,
                options: [
                    {title: 'Yes, with alarm response training', value: 0},
                    {title: 'Yes, but without training', value: 3},
                    {title: 'No', value: 4}
                ]
            },
            {
                name: 'alarmResponsePerson',
                title: 'Who responds to alarms? and how?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.01,
                insiderMult: 0.001,
                options: [
                    {title: 'On-site with local law enforcement and less than 10 minutes of response time', value: 0},
                    {title: 'On-site with local law enforcement bute more than 10 minutes of response time', value: 1},
                    {title: 'On-site guards with local law enforcement as backup', value: 2},
                    {title: 'On-site guards but they do not have training', value: 3},
                    {title: 'No alarm response', value: 4}
                ]
            },
            {
                name: 'alarmTrain',
                title: 'How is alarm response trained?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.01,
                insiderMult: 0.001,
                options: [
                    {title: 'All personnel are trained and training is documented', value: 0},
                    {title: 'All personnel are trained', value: 1},
                    {title: 'No formal training but personnel are instructed in alarm response', value: 2},
                    {title: 'Procedure is documented but no training is performed', value: 3},
                    {title: 'No training exists', value: 4}
                ]
            }
        ],
        data: {}
    },
    {
        dictatedByChem: false,
        key: 'infoSec',
        section: 'Chemical Security Assessment',
        title: 'Step 2e - Information Security',
        questions: [
            {
                name: 'informationProtected',
                title: 'Is sensitive information labelled and protected to prevent risk of release/loss?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.03,
                insiderMult: 0.003,
                options: [
                    {title: 'Yes, enforced and regularly inspected', value: 0},
                    {title: 'Yes, and is enforced', value: 1},
                    {title: 'Yes', value: 2},
                    {title: 'No', value: 4}
                ]
            },
            {
                name: 'inappropriateLoss',
                title: 'Is sensitive information protected from inappropriate release or loss?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.02,
                insiderMult: 0.002,
                options: [
                    {title: 'Yes', value: 0},
                    {title: 'No', value: 4}
                ]
            },
            {
                name: 'communicationPolicy',
                title: 'Are there communication policies for sensitive information?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.02,
                insiderMult: 0.001,
                options: [
                    {title: 'Means of communication are controlled', value: 0},
                    {title: 'Yes, but not enforced', value: 1},
                    {title: 'Yes, but not trained on', value: 2},
                    {title: 'No policy exists', value: 4}
                ]
            },
            {
                name: 'cyberProtection',
                title: 'Are electronic crticial information systems protected from cyber attacks?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.015,
                insiderMult: 0.001,
                options: [
                    {title: 'Yes', value: 0},
                    {title: 'No', value: 4}
                ]
            },
            {
                name: 'publicDisclosure',
                title: 'Are there policies for public disclosure of information?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.02,
                insiderMult: 0.001,
                options: [
                    {title: 'Sensitive information is screened prior to release', value: 0},
                    {title: 'Yes, but not enforced', value: 1},
                    {title: 'Policy exist but is not trained upon', value: 2},
                    {title: 'No defined penalties', value: 3},
                    {title: 'No consideration regarding information sensitivity', value: 4}
                ]
            }
        ],
        data: {}
    },
    {
        dictatedByChem: false,
        key: 'disposal',
        section: 'Chemical Security Assessment',
        title: 'Step 2f - Chemical Disposal (or Sale when Appropriate)',
        questions: [
            {
                name: 'buyerVerification',
                title: 'What type of verification of the buyer is required prior to sales?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0,
                insiderMult: 0.007,
                options: [
                    {title: 'Only people with documents ties to verified chemical laboratories can purchase chemicals', value: 0},
                    {title: 'Only people with documents ties to chemical laboratories can purchase chemicals', value: 2},
                    {title: 'No verification, anyone can purchase', value: 4}
                ]
            },
            {
                name: 'externalTransport',
                title: 'If transported externally, how are external carriers selected?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.01,
                insiderMult: 0,
                options: [
                    {title: 'Licensed carriers with appropriate security', value: 0},
                    {title: 'Lowest cost', value: 4}
                ]
            },
            {
                name: 'facilityTransport',
                title: 'How are chemicals transported from this facility?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.01,
                insiderMult: 0.001,
                options: [
                    {title: 'Only via licensed carriers or secured pipelines', value: 0},
                    {title: 'Any transport method including personal vehicles', value: 4}
                ]
            },
            {
                name: 'disposalControl',
                title: 'What is the level of control of chemicals prior to disposal?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.01,
                insiderMult: 0.001,
                options: [
                    {title: 'Secured and operated by trained personnel', value: 0},
                    {title: 'No security on chemicals awaiting disposal', value: 4}
                ]
            },
            {
                name: 'disposalMethod',
                title: 'How are chemicals disposed?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.01,
                insiderMult: 0.001,
                options: [
                    {title: 'In compliance with best practices and processes are followed', value: 0},
                    {title: 'Stored indefinitely in a secured warehouse with periodic inventory', value: 2},
                    {title: 'No disposal plan, stored indefinitely with no inventory or security', value: 4}
                ]
            }
        ],
        data: {}
    },
    {
        dictatedByChem: false,
        key: 'program',
        section: 'Chemical Security Assessment',
        title: 'Step 2g - Program Management',
        questions: [
            {
                name: 'securityRoles',
                title: 'Are there clearly defined roles and responsibilities for chemical security?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.01,
                insiderMult: 0.01,
                options: [
                    {title: 'Yes, roles are documented and communicated', value: 0},
                    {title: 'Yes', value: 2},
                    {title: 'No', value: 4}
                ]
            },
            {
                name: 'securityCommitment',
                title: 'Has the institution made a commitment to security?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.01,
                insiderMult: 0.01,
                options: [
                    {title: 'Yes, funding is provided to allow security requirements are met', value: 0},
                    {title: 'Yes', value: 2},
                    {title: 'No', value: 4}
                ]
            },
            {
                name: 'securityDocumentation',
                title: 'Does the institution have a thorough chemical security documentation?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.01,
                insiderMult: 0.01,
                options: [
                    {title: 'Yes', value: 0},
                    {title: 'No', value: 4}
                ]
            },
            {
                name: 'securityDrills',
                title: 'Does the institution conduct security drills or exercises?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.01,
                insiderMult: 0.01,
                options: [
                    {title: 'Yes', value: 0},
                    {title: 'No', value: 4}
                ]
            },
            {
                name: 'securityReview',
                title: 'Does the institution periodically review the security program?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.01,
                insiderMult: 0.01,
                options: [
                    {title: 'Yes', value: 0},
                    {title: 'No', value: 4}
                ]
            },
            {
                name: 'safetyRoles',
                title: 'Are there clearly defined roles and responsibilities for chemical safety?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.01,
                insiderMult: 0.01,
                options: [
                    {title: 'Yes, roles are documented and communicated', value: 0},
                    {title: 'Yes', value: 2},
                    {title: 'No', value: 4}
                ]
            },
            {
                name: 'safetyCommitment',
                title: 'Has the institution made a commitment to safety?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.01,
                insiderMult: 0.01,
                options: [
                    {title: 'Yes, funding is provided to allow safety requirements are met', value: 0},
                    {title: 'Yes', value: 2},
                    {title: 'No', value: 4}
                ]
            },
            {
                name: 'safetyDrills',
                title: 'Does the institution conduct safety drills or exercises?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.01,
                insiderMult: 0.01,
                options: [
                    {title: 'Yes', value: 0},
                    {title: 'No', value: 4}
                ]
            },
            {
                name: 'safetyDocumentation',
                title: 'Does the institution have a thorough chemical safety documentation?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.01,
                insiderMult: 0.01,
                options: [
                    {title: 'Yes', value: 0},
                    {title: 'No', value: 4}
                ]
            },
            {
                name: 'safetyReview',
                title: 'Does the institution periodically review teh safety program?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.01,
                insiderMult: 0.01,
                options: [
                    {title: 'Yes', value: 0},
                    {title: 'No', value: 4}
                ]
            },
            {
                name: 'incidentTracking',
                title: 'Are the incidents tracked? after action reports drafted? and followed-up?',
                type: 'radio',
                theftMult: 0,
                populatedMult: 0,
                industrialMult: 0,
                outsiderMult: 0.01,
                insiderMult: 0.01,
                options: [
                    {title: 'Yes', value: 0},
                    {title: 'Yes, incidents tracked, and after actions drafted only', value: 1},
                    {title: 'Yes, incidents are tracked only', value: 3},
                    {title: 'No', value: 4}
                ]
            }
        ],
        data: {}
    }
  ];
  