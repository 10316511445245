import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';

export default function QuestionnaireTile (props) {
    return (
        // The box shadow on the tile is found inside the index.css file
        <Link to={`/questionnaire/${props.title}`}>
            <div onClick={props.setPage} className='CSBoxShadow w-[200px] h-[200px] bg-black rounded-[15px] bg-transparent border-[1px] border-black flex justify-evenly flex-col cursor-pointer'>
                <FontAwesomeIcon icon={props.icon} color={props.color} className={`text-[80px]`} />
                <Typography className='oswald font-bold text-[28px] self-center'>{props.title}</Typography>
            </div>
        </Link>
    );
}