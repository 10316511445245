import React from 'react';

import { Flex, Typography } from 'antd';
import { useSelector } from 'react-redux';
import useNotification from 'antd/es/notification/useNotification';

import LabSetupTable from './LabSetupTable';
import ChemicalsTable from './ChemicalsTable';
import LabSelector from '../LabSelector';

export default function Setup () {
    const { accessLevel } = useSelector(state => state.authentication.userDetails);
    const [api, context] = useNotification();

    return (
        <div className='Setup w-[82vw]'>
            {context}
            <Flex justify='space-between' align='baseline'>
                <Typography className='oswald text-[48px] font-bold mb-[37px]'>Setup</Typography>

                <LabSelector />
            </Flex>
            
            <article>
                <ChemicalsTable notificationAPI={api} />
                
                { accessLevel >= 2 && <LabSetupTable /> }
            </article>
        </div>
    )
}