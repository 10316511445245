import React from 'react';

import { Flex, Typography, Input, Button, notification } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlask, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { getFirestore, doc, getDoc } from '@firebase/firestore';
import OTPInput from 'react-otp-input';

export default function LabRegister(props) {

    const handleSubmit = async () => {
        props.setIsLoading(true);

        try {
            const firestore = getFirestore();

            // Check for the lab code inputted
            const inputtedLab = await getDoc(doc(firestore, 'labs', props.labCode));

            if(!inputtedLab.exists()) {
                throw new Error('Requested lab does not exist!');
            } else {
                props.setCurrentPage(props.currentPage+1);
            }

        } catch (err) {
            if(err.message === 'Requested lab does not exist!') {
                props.api.error({
                    message: 'Invalid Lab Code!',
                    description: 'The lab code you entered does not belong to any lab.',
                    placement: 'topRight'
                })
            } else {
                // console.log(err);
                props.api.error({
                    message: 'An Error occurred!',
                    description: 'An error occurred while attempting to check the lab.',
                    placement: 'topRight'
                })
            }

        } finally {
            props.setIsLoading(false);
        }
    };

    return (
        <Flex 
            style={{
                width: '40vw',
                minWidth: '400px',
                padding: 30,
                // height: '60vh'
            }}
            align='center'
            vertical
        >

            <Typography className='oswald accent text-[28px] mb-[60px]'><FontAwesomeIcon icon={faFlask} className='accent me-[20px]' /> Please Enter Lab Code</Typography>
            
            <OTPInput 
                value={props.labCode}
                onChange={(val) => props.setLabCode(val.toUpperCase())}
                numInputs={6}
                containerStyle={{
                    justifyContent: 'space-evenly'
                }}
                inputStyle={{
                    margin: '0 15px',
                    width: 50,
                    borderColor: '#B6B6B5',
                    borderWidth: 1
                }}
                renderInput={(props) => <Input {...props} />}
                placeholder='USM001'
            />
            <Button 
                htmlType='submit' 
                block 
                className='CSSuccess mt-[60px] mb-[32px]' 
                disabled={props.labCode.length !== 6 && !props.isLoading} 
                onClick={handleSubmit}
            >
                Next
            </Button>
        
        </Flex>
    )
}