import React from 'react';

import { Upload, Button } from 'antd';
import { getFirestore, doc, writeBatch } from '@firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faDownload } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { parse } from 'papaparse';

export default function MassImportButton(props) {
    const { assignedLab } = useSelector(state => state.authentication.userDetails);

    const { notificationAPI, isUploading, setIsUploading } = props;

    return (
        <Upload disabled={isUploading} showUploadList={false} maxCount={1} accept='.csv' beforeUpload={file => {
            parse(file, {
                complete: res => {
                    try {
                        setIsUploading(true);
                        const parsed = parseData(res.data);

                        // Write the data to the DB
                        const db = getFirestore();
                        const batchProcess = writeBatch(db);

                        // Put all the output into the batch process
                        Object.keys(parsed).forEach(cas => {
                            batchProcess.set(doc(db, 'Chemicals', assignedLab, 'chemicals', cas), parsed[cas]);
                        });

                        // Push it all to the DB Now
                        batchProcess.commit().then(_ => setIsUploading(false));

                        
                    } catch (err) {
                        notificationAPI.error({message: 'An error occured while uploading the chemicals!'});
                    }
                }
            });
        }}>
            <Button disabled={isUploading} icon={isUploading ? <FontAwesomeIcon icon={faSpinner} spinPulse/> : <FontAwesomeIcon icon={faDownload}/>}> Import Chemicals </Button>
        </Upload>
    );
}

function parseData(data) {
    // Get the first column first (supposed to be the headers)
    const positions = {
        cas: -1,
        name: -1,
        state: -1
    };

    data[0].forEach((el, pos) => {
        const lower = el.toLowerCase();
        if(positions.cas === -1 && lower.includes('cas')) {
            positions.cas = pos;
        } else if (positions.name === -1 && lower.includes('name')) {
            positions.name = pos;
        } else if (positions.state === -1 && lower.includes('state')) {
            positions.state = pos;
        }
    });

    // Fail the process if not formatted properly...
    if(Object.values(positions).includes(-1)) {
        throw(new Error('The data is not formatted correctly. Missing Columns'));
    }

    // Form the data that needs to be uploaded
    const uploadJSON = {};

    const errorList = [];

    for(let key in data) {
        
        // Clean up the name and assign it to a value
        if(!/\d*-\d*-\d*/g.test(data[key][positions.cas])) {
            errorList.push(key);
        } else {
            uploadJSON[data[key][positions.cas].match(/\d*-\d*-\d*/g)] = {
                name: data[key][positions.name],
                // TODO: perform some basic validation here
                state: data[key][positions.state],
                hasSDS: false,
                quantity: null,
                stealable: null,
                volatile: null,
                waterPersist: null,
                soilPersist: null
            };
        }
    }

    // console.log(errorList, uploadJSON);
    return uploadJSON;
}