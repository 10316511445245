import React, { useState } from 'react';

import { Form, Typography, Input, Button, notification } from 'antd';
import { faUser, faLock, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAuth, signInWithEmailAndPassword } from '@firebase/auth';
import { useDispatch } from 'react-redux';
import { setUserDetails } from '../../providers/userSlice';
import { getFirestore, doc, getDoc, onSnapshot } from '@firebase/firestore';

export default function LoginForm (props) {

    const { listeners, setListeners } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();

    const dispatch = useDispatch();

    return (
        <div className='LoginForm'>
            {contextHolder}
            <Form
                style={{
                    width: '40vw',
                    minWidth: '400px',
                    padding: 30,
                    height: '60vh'
                }}
                onFinish={async (val) => {
                    // Put the component in a loading state
                    setIsLoading(true);

                    try {
                        // Attempt to log in
                        const auth = getAuth();
                        const db = getFirestore();

                        const newUser = await signInWithEmailAndPassword(auth, val.email, val.password);

                        // Set the store value to the value that is in the database
                        const userDoc = await getDoc(doc(db, 'users', newUser.user.uid));
                        dispatch(setUserDetails({...userDoc.data(), uid: userDoc.id}));

                        // Set a listener to update the value if it changes
                        const unsub = onSnapshot(doc(db, 'users', newUser.user.uid), doc => {
                            dispatch(setUserDetails({...doc.data(), uid: doc.id}));
                        });

                        setListeners(listeners.push(unsub));
                        
                    } catch (err) {
                        // Display and error to the user
                        api.error({
                            message: 'Login failed!',
                            description: 'An error occurred while attempting to log in. Please try again.'
                        });
                    } finally {
                        // Remove it from the loading state
                        setIsLoading(false);
                    }
                }}
            >
                
                <Typography className="oswald mb-[64px] text-center text-[48px]">Login</Typography>

                <Form.Item
                    name='email'
                    rules={[{
                        required: true
                    }]}
                >
                    <Input 
                        type="email" 
                        placeholder="Email" 
                        prefix={<FontAwesomeIcon icon={faUser} className='me-[12px] accent' />} 
                        className="CSInput oswald font-light text-[20px]"
                    />
                </Form.Item>

                <Form.Item
                    name='password'
                    rules={[{
                        required: true
                    }]}
                >
                    <Input 
                        type="password" 
                        placeholder="Password" 
                        prefix={<FontAwesomeIcon icon={faLock} className='me-[12px] accent' />} 
                        className="CSInput oswald font-light text-[20px]"
                    />
                </Form.Item>

                <Form.Item>
                    <Button htmlType="submit" block className="CSSuccess mt-[48px]" disabled={isLoading}> {isLoading ? <FontAwesomeIcon icon={faSpinner} spinPulse={true} color='white' /> : 'Login'} </Button>
                </Form.Item>

                <Typography className='oswald text-center text-[24px]'>
                    Don't Have an account? <br />
                    <span className='tertiary cursor-pointer' onClick={props.setLogin}>
                        Register
                    </span>
                </Typography>
            </Form>
        </div>
    );
}