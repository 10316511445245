import React, { useState } from 'react';

import { Avatar, Flex, Modal, Switch, Typography } from 'antd';
import Search from 'antd/es/input/Search';
import { collection, getDocs, getFirestore, limit, orderBy, query, startAt, where } from '@firebase/firestore';
import { useSelector } from 'react-redux';
import { getDownloadURL, getStorage, ref } from '@firebase/storage';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function StaffSearchModal(props) {
    const { assignedLab } = useSelector(state => state.authentication.userDetails);
    const { isOpen, setIsOpen, handleSwitch } = props;

    const [isLoading, setIsLoading] = useState(false);

    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const fetchResults = async () => {
        try {
            setIsLoading(true);
            const db = getFirestore();

            const results = await getDocs(query(collection(db, 'users'), where('assignedLab', '==', assignedLab), where('accessLevel', '==', 1), orderBy('name_insensitive'), startAt(searchTerm.toLowerCase()), limit(15)));

            const resultsWithPfp = await Promise.all(results.docs.map(async user => {
                const storage = getStorage();
                
                return {
                    ...user.data(),
                    id: user.id,
                    pfp: await getDownloadURL(ref(storage, `/users/${assignedLab}/${user.id}/pfp.png`))
                }
            }));

            setSearchResults(resultsWithPfp);
            
            setIsLoading(false);
        } catch (err) {
            // console.log(err);
        }
    };

    const closeForm = () => {
        setSearchTerm('');
        setIsLoading(false);
        setSearchResults([]);
        setIsOpen(false);
    }

    return (
        <Modal open={isOpen} footer={false} onCancel={closeForm}>
            <Typography className='oswald text-[24px] font-bold mb-2'>Search Staff</Typography>
            <Search 
                className=''
                allowClear
                size='large'
                disabled={isLoading}  
                loading={isLoading}
                value={searchTerm} 
                onChange={(e) => setSearchTerm(e.target.value)} 
                onSearch={fetchResults}
            />
            <section className='min-h-[200px] max-h-[400px] overflow-y-scroll p-3'>
                
                {/* Display the data from the results */}
                {isLoading ? <FontAwesomeIcon icon={faSpinner} spinPulse /> : searchResults.map(user =>
                    <Flex className='w-[100%] my-4' align='center' gap={20} id={user.id}>
                        <Avatar shape='square' size='large' src={user.pfp} />
                        <Flex vertical={true}>
                            <Typography className='text-[16px]'> {user.name} ({user.matric}) </Typography>
                            <Typography className='text-[12px] font-light'> {user.email} </Typography>
                        </Flex>
                        <div className='grow-[2]'></div>
                        <Switch defaultValue={user.active} onChange={val => handleSwitch(user.id, val)}/>
                    </Flex>
                )}

            </section>
        </Modal>
    );
}