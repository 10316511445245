import React, { useState } from "react";

import { collection, documentId, getDocs, getFirestore, limit, orderBy, query, startAt } from "@firebase/firestore";
import { Flex, Modal } from "antd";
import { useSelector } from "react-redux";
import Search from 'antd/es/input/Search';
import Typography from "antd/es/typography/Typography";
import ChemicalModalResult from "./ChemicalModalResult";

export default function ChemicalSearchModal(props) {
    const { isOpen, setIsOpen, setIsQuickModalOpen } = props;
    const { assignedLab } = useSelector(state => state.authentication.userDetails);

    // Some modal sheet settings
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    

    const closeForm = () => {
        setSearchResults([]);
        setSearchTerm('');
        setIsOpen(false);
    };

    // To request the data from the back-end
    const fetchResults = async () => {
        try {
            setIsLoading(true);
            const db = getFirestore();

            const results = await getDocs(query(collection(db, `Chemicals/${assignedLab}/chemicals`), orderBy(documentId()), startAt(searchTerm), limit(15)));
            
            setSearchResults(results.docs);

            setIsLoading(false);
        } catch (err) {
            // TODO: implement some error handling here
        }
    };

    return (
        <Modal open={isOpen} onCancel={closeForm} footer={false}>
            <Typography className='oswald text-[24px] font-bold mb-2'> Chemical Search </Typography>
            <Search 
                className=''
                allowClear
                size='large'
                placeholder='CAS No.'
                disabled={isLoading}  
                loading={isLoading}
                value={searchTerm} 
                onChange={(e) => setSearchTerm(e.target.value)} 
                onSearch={fetchResults}
            />

            <Flex className="min-h-[200px] max-h-[400px] overflow-y-scroll p-3" vertical>
                {searchResults.map(chem => {
                    return <ChemicalModalResult chemical={chem} setIsQuickModalOpen={setIsQuickModalOpen} fetchResults={fetchResults} />;
                })}
            </Flex>
        </Modal>
    );
}

