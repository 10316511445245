import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";
import { combineReducers } from "@reduxjs/toolkit";

import userSlice from "./userSlice";

const persistConfig = {
    key: 'root',
    storage,
}

const reducers = combineReducers({
    authentication: userSlice,
});

const persistedReducers = persistReducer(persistConfig, reducers);

export default configureStore({
    reducer: persistedReducers
});