import React from 'react';
import { Flex } from 'antd';

import logo from '../../images/logo.png';

export default function CSHeader () {
    return (
        <Flex justify="space-between">
            {/* The logo and App Name */}
            <Flex align="center" gap={8}>
                <img src={logo} className="logo" alt="logo" />
                <span className="oswald primary text-[48px] font-bold">Chem<span className="secondary-accent">RAS</span></span>
            </Flex>
            {/* The Nav Options */}
            {/* <Flex align="flex-end" gap={100}>
                <span className="oswald primary text-[24px]">Home</span>
                <span className="oswald primary text-[24px]">About</span>
            </Flex> */}
        </Flex>
    );
}