import React, { useState, useEffect } from "react";

import { Link, useLocation } from "react-router-dom";
import { Form, Input, Radio, Flex, Typography, Button, notification } from 'antd';
import { getFirestore, getDoc, doc, updateDoc } from "@firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

import { ChemSAMJSON } from "../../JSON/ChemSAM";
import { useSelector } from "react-redux";

export default function ChemSAMModule () {
    const { assignedLab } = useSelector(state => state.authentication.userDetails);

    const [dbVals, setDbVals] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [api, context] = notification.useNotification();

    const {state} = useLocation();
    const navigate = useNavigate();
    const selectedSectionData = ChemSAMJSON.filter(item => item.key === state)[0];

    const fetchDefaultVals = async () => {
        try {
            const db = getFirestore();

            const detailsSnap = await getDoc(doc(db, 'labs', assignedLab));

            setDbVals(detailsSnap.data().security ?? {});
        } catch (err) {
            api.error({
                message: 'Failed to fetch values. Check your connection and try again!',
                duration: 3
            });
        }
    }

    const handleSubmit = async (vals) => {
        try {
            setIsSubmitting(true);

            const db = getFirestore();

            await updateDoc(doc(db, 'labs', assignedLab), {
                security: {
                    ...dbVals,
                    [state]: vals
                }
            });

            navigate('/setup');
            scrollToTop();
        } catch (err) {
            api.error({
                message: 'Failed to upload data, check your internet connection and try again!',
                duration: 3
            });
        }
    }

    useEffect(_ => {
        Promise.all([fetchDefaultVals()]).then(_ => {
            setIsLoading(false);
        });
    }, []);

    return (
        <div className="ChemSAMModule">
            {context}
            <Typography className='oswald text-[48px] font-bold mb-[37px]'>Lab Setup</Typography>

            <div className="CSBoxShadow CSBorder bg-white w-[80vw] px-10 py-5 mb-[30px]">
                {
                    isLoading ?
                    <FontAwesomeIcon icon={faSpinner} spinPulse />
                    :
                    <Form initialValues={dbVals[state]} onFinish={handleSubmit} disabled={isSubmitting}>
                        <Flex wrap='wrap'>
                            {selectedSectionData.questions.map(({title, type, name, options}) => 
                                <div className='w-[50%]'>
                                    <Typography className='CSFormLabel mb-2'>{title}</Typography>
                                    {generateField({name, type, options})}
                                </div>
                            )}
                        </Flex>

                        <hr className="border-[#979797] mb-5" />
                        
                        <Flex justify='flex-end' gap={15}>
                            <Link to='/setup'>
                                <Button type="primary" size="large" danger className="roboto font-bold" onClick={_ => scrollToTop()} disabled={isSubmitting}>
                                    Cancel
                                </Button>
                            </Link>
                            <Button type="primary" size="large" className="roboto bg-[#42CA4E] font-bold hover:!bg-[#68cf70]" htmlType="submit" disabled={isSubmitting}>
                                {isSubmitting ? <FontAwesomeIcon icon={faSpinner} spinPulse /> : 'Update'}
                            </Button>
                        </Flex>
                    </Form>
                }
            </div>
        </div>
    );
}

function generateField({name, type, options}) {
    let field = <></>;

    switch(type) {
        case 'radio':
            field = <Radio.Group >
                {options.map(opt => <Radio style={{display: 'block'}} value={opt.value}>{opt.title}</Radio>)}
            </Radio.Group>
            break;
            
        case 'radio button':
            field = <Radio.Group >
                {options.map(opt => <Radio.Button value={opt.value}>{opt.title}</Radio.Button>)}
            </Radio.Group>
            break;

        default:
            field = <Input type={type} />
    }

    return <Form.Item className='pe-5' name={name} rules={[{required: true}]}>
        {field}
    </Form.Item>
}

function scrollToTop() {
    document.getElementById('Main').scrollTo({top: 0, left: 0, behavior: 'smooth'});
}