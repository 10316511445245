import React, { useState } from 'react';

import { Layout } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlask, faHouseChimney, faPenSquare, faUserGear, faHatHard, faWarning, faAddressCard } from '@fortawesome/free-solid-svg-icons';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Sidebar from './Sidebar';
import Dashboard from './dashboard/dashboard';
import Users from './users/users';
import Questionnaire from './questionnaire/questionnaire';
import HIRARC from './questionnaire/HIRARC';
import ChemSAM from './questionnaire/ChemSAM/ChemSAM';
import ChemSAMChart from './questionnaire/ChemSAM/ChemSAMChart';
import Setup from './setup/setup';
import ChemSAMModule from './setup/ChemSAMModule';
import Profile from './Profile/Profile';

export default function Main (props) {
    const { active, verified } = useSelector(state => state.authentication.userDetails ?? {active: false, verified: false});

    const { listeners, setListeners } = props;

    const [collapsed, setCollapsed] = useState(true);

    // Pages that you want to show up on the menu
    const PageList = [
        {
            key: '/',
            icon: <FontAwesomeIcon icon={faHouseChimney} />, 
            ...(!collapsed) && {label: 'Dashboard'},
            adminOnly: false,
            element: <Dashboard />,
        },
        {
            key: '/users',
            icon: <FontAwesomeIcon icon={faUserGear} />,
            ...(!collapsed) && {label: 'Users'},
            adminOnly: true,
            element: <Users />,
        },
        {
            key: '/questionnaire',
            icon: <FontAwesomeIcon icon={faPenSquare} />,
            ...(!collapsed) && {label: 'Questionnaire'},
            adminOnly: false,
            element: <Questionnaire />,
        },
        {
            key: '/setup',
            icon: <FontAwesomeIcon icon={faFlask} />,
            ...(!collapsed) && {label: 'Setup'},
            adminOnly: false,
            element: <Setup />,
        },
        // Only display this to users who have been rejected
        !active && !verified && {
            key: '/profile',
            icon: <FontAwesomeIcon icon={faAddressCard} />,
            ...(!collapsed) && {label: 'Profile'},
            adminOnly: false,
            element: <Profile />
        }
    ]    

    // Assessments that will show up on the /questionnaire route
    // TODO: move this to a cloud as it always need to be synced with the list in the questionnaire.jsx file
    const questionnaireList = [
        {
            title: 'HIRARC',
            icon: faHatHard,
            color: '#FFB11A',
            element: <HIRARC />
        },
        {
            title: 'Chem-SAM',
            icon: faWarning,
            color: '#F74707',
            element: <ChemSAM />
        }
    ];

    
    return (
        <Layout id='Main' className='Main flex flex-col w-[100vw] h-[100vh] overflow-y-scroll'>
            <BrowserRouter >
                {/* Side Nav Menu */}
                <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} PageList={PageList} listeners={listeners} setListeners={setListeners} />

                {/* The content of the page */}
                <Routes>
                    
                    {/* Main routes */}
                    {PageList.map(({element, key}) => <Route path={key} element={element} />)}

                    {/* Questionnaire routes */}
                    {questionnaireList.map(({element, title}, i) => <Route path={`/questionnaire/${title}`} element={element} />)}

                    <Route path='questionnaire/Chem-SAM/result' element={<ChemSAMChart />} />

                    <Route path='setup/module' element={<ChemSAMModule />} />
                
                </Routes>
            </BrowserRouter>

        </Layout>
    )
}