import React, { useEffect, useState } from 'react';

import { getFirestore, getDocs, collection, getDoc, doc, query, orderBy, limit } from '@firebase/firestore';
import { Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faScroll, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

import EmptyTableMessage from '../EmptyTableMessage';

export default function ChemSAMHistoryTable () {
    const { assignedLab } = useSelector(state => state.authentication.userDetails);

    const [ChemSAMHistory, setChemSAMHistory] = useState([{
        chemical: <FontAwesomeIcon icon={faSpinner} spinPulse />,
        chemName: <FontAwesomeIcon icon={faSpinner} spinPulse />,
        email: <FontAwesomeIcon icon={faSpinner} spinPulse />,
        likeTheft: <FontAwesomeIcon icon={faSpinner} spinPulse />,
        conTheft: <FontAwesomeIcon icon={faSpinner} spinPulse />,
    }]);

    const navigate = useNavigate();

    const ChemSAMHeaders = ['CAS No.', 'Name', 'User', 'Likelihood of Theft', 'Consequences of Malicious use'];

    const fetchChemSAM = async () => {
        try {
            const db = getFirestore();

            // This section may need to be restructured as it is quite resource intensive
            const chemSAMSnapshot = await getDocs(query(collection(db, 'ChemSAM', assignedLab, 'analyses'), limit(5), orderBy('timeStamp', 'desc')));

            // Fetch the rest of the details that we need
            let chemSAMWithDetails = await Promise.all(chemSAMSnapshot.docs.map(async snap => {
                let user = await getDoc(doc(db, 'users', snap.data().user));
                let chemical = await getDoc(doc(db, 'Chemicals', assignedLab, 'chemicals', snap.data().chemical));
                return {
                    ...snap.data(),
                    email: user.exists() ? user.data().email : 'Deleted User',
                    chemName: chemical.exists() ? chemical.data().name : 'Deleted Chemical'
                }
            }));

            setChemSAMHistory(chemSAMWithDetails);
        } catch (err) {
            
        }
    }

    useEffect(_ => {
        fetchChemSAM(assignedLab);
    }, [assignedLab]);

    return (
        <div className="ChemSAMHistoryTable">
            <Typography className='font-bold text-[26px] oswald'>Chem-SAM</Typography>
            <div className='CSBorder CSBoxShadow !rounded-[5px]'>
                <div className="CSChemSAMDashGrid my-[5px]">
                    {ChemSAMHeaders.map(header => <Typography className='oswald text-[20px] font-bold'>{header}</Typography>)}
                </div>
                <hr className='border-black'/>
                <div>
                    {ChemSAMHistory.length === 0 ? <EmptyTableMessage message='No analyses performed yet' icon={faScroll} /> : ChemSAMHistory.map(item => 
                        <div className='CSChemSAMDashGridContent p-1' onClick={_ => {
                            navigate('/questionnaire/Chem-SAM/result', {state: item});
                            scrollToTop();
                        }}>
                            <Typography className={`roboto text-[16px]`}>{item.chemical}</Typography>
                            <Typography className={`roboto text-[16px]`}>{item.chemName}</Typography>
                            <Typography className={`roboto text-[16px] text-ellipsis overflow-hidden whitespace-nowrap`}>{item.email}</Typography>
                            <Typography className={`roboto text-[16px]`}>{getLikelihoodLabel(item.likeTheft)}</Typography>
                            <Typography className={`roboto text-[16px]`}>{getSeverityLabel(item.conTheft)}</Typography>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

function scrollToTop() {
    document.getElementById('Main').scrollTo({top: 0, left: 0, behavior: 'smooth'});
}

function getSeverityLabel(val) {
    if (val > 3.5) {
        return 'Catastrophic';
    } else if (val > 2.5) {
        return 'Fatal';
    } else if (val > 1.5) {
        return 'Serious';
    } else if (val > 0.5) {
        return 'Minor';
    } else {
        return 'Negligible';
    }
}

function getLikelihoodLabel(val) {
    if (val > 3.5) {
        return 'Most Likely';
    } else if (val > 2.5) {
        return 'Possible';
    } else if (val > 1.5) {
        return 'Conceivable';
    } else if (val > 0.5) {
        return 'Remote';
    } else {
        return 'Inconceivable';
    }
}