import React from 'react';

import { useSelector } from 'react-redux';

import PendingScreen from './PendingScreen';
import ActiveScreen from './ActiveScreen';
import DisabledScreen from './DisabledScreen';
import RejectedScreen from './RejectedScreen';



export default function Dashboard () {
    
    const { verified, active } = useSelector(state => state.authentication.userDetails ?? {verified: false, active: true});

    return (
        <div className='Dashboard w-[82vw]'>
            {
                active && verified ?
                    <ActiveScreen />
                    :
                    active ?
                        <PendingScreen />
                        :
                        verified ?
                            <DisabledScreen />
                            :
                            <RejectedScreen />
            }
        </div>
    )
}