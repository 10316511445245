import React, { useState } from 'react';

import { Typography, Space, Tooltip, Upload, Button, notification } from 'antd';
import { getFirestore, doc, updateDoc } from '@firebase/firestore';
import { getStorage, ref, uploadBytes, deleteObject } from '@firebase/storage';
import { faSpinner, faEye, faTrash, faUpload, faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import QuickAnalysisModal from './QuickAnalysisModal';

export default function ChemicalRow(props) {
    const { assignedLab, accessLevel } = useSelector(state => state.authentication.userDetails);
    const { isLoading, isUploading, setIsUploading, chem, fetchToPage, notificationAPI } = props;

    const [isModalOpen, setIsModalOpen] = useState(false);

    // const handleDeleteFile = async (uid) => {
    //     try {
    //         setIsUploading(true);

    //         const db = getFirestore();
    //         const storage = getStorage();

    //         const fileRef = ref(storage, `/chemicals/${assignedLab}/SDS/${uid}`);

    //         await deleteObject(fileRef);

    //         await updateDoc(doc(db, 'Chemicals', assignedLab, 'chemicals', uid), {
    //             hasSDS: false
    //         });

    //     } catch (err) {
    //         // console.log(err);
    //     } finally {
    //         setIsUploading(false);
    //         fetchChemicals();
    //     }
    // }


    const uploadFile = async (file, uid) => {
        try {
            setIsUploading(true);
            const storage = getStorage();
            const db = getFirestore();

            await uploadBytes(ref(storage, `/chemicals/${assignedLab}/SDS/${uid}.pdf`), file);
            
            // Once it has been uploaded, update the original doc to state it is available
            await updateDoc(doc(db, 'Chemicals', assignedLab, 'chemicals', uid), {
                hasSDS: true
            });

            fetchToPage();
        } catch (err) {
            notificationAPI.error({message: 'An error occured while uploading the SDS.', duration: 3});
            console.log(err);
        } finally {
            setIsUploading(false);
            // fetchChemicals();
        }
    }

    return (
        <div className='CSChemGridContent px-2 py-3'>
            <QuickAnalysisModal cas={chem.id}  isOpen={isModalOpen} setIsOpen={setIsModalOpen} notificationAPI={notificationAPI} fetchToPage={fetchToPage} initialValues={chem} />
            <Typography className='roboto text-[16px]'>{isLoading ? <FontAwesomeIcon icon={faSpinner} spinPulse /> : chem.id}</Typography>
            <Typography className='roboto text-[16px]'>{isLoading ? <FontAwesomeIcon icon={faSpinner} spinPulse /> : chem.name}</Typography>
            <Typography className='roboto text-[16px]'>{isLoading ? <FontAwesomeIcon icon={faSpinner} spinPulse /> : chem.state}</Typography>
            <Typography className='roboto text-[16px]'>{isLoading ? <FontAwesomeIcon icon={faSpinner} spinPulse /> : getStatusMessage(chem)}</Typography>
            <Space size='middle'>
                {/* Will be edited in later to work */}
                {accessLevel > 1 && <Tooltip title='Set Quick Analysis Values'>
                    <Button disabled={isUploading} onClick={() => setIsModalOpen(true)}> <FontAwesomeIcon icon={faStopwatch} color={getQuickValuesColor(chem)} /> </Button>
                </Tooltip>}
                {/* Upload button */}
                <Tooltip title='Upload SDS'>
                    <Upload accept='.pdf' maxCount={1} showUploadList={false} beforeUpload={file => uploadFile(file, chem.id)}>
                        <Button disabled={isUploading}> <FontAwesomeIcon icon={isUploading ? faSpinner : faUpload} spinPulse={isUploading} /> </Button>
                    </Upload>
                </Tooltip>
                {/* Download button - Only works if SDS is uploaded */}
                <Tooltip title='View SDS'>
                    <a href={isLoading ? '' : chem.downloadLink} target='_blank'>
                        <Button disabled={isLoading || isUploading ? true : !chem.hasSDS}> <FontAwesomeIcon icon={faEye}/> </Button>
                    </a>
                </Tooltip>
                {/* This item was removed becuase of requirements but can be uncommented and will work (need to uncomment the function above as well) */}
                {/* Remove SDS Button - Only works if SDS is uploaded */}
                {/* {accessLevel > 1 && <Tooltip title='Remove SDS'>
                    <Button disabled={isLoading || isUploading ? true : !chem.hasSDS} onClick={() => handleDeleteFile(chem.id)}> <FontAwesomeIcon icon={faTrash}/> </Button>
                </Tooltip>} */}
            </Space>
        </div>
    );
}

function getQuickValuesColor(props) {

    const {quantity, soilPersist, stealable, volatile, waterPersist} = props;

    // If all of them have a value, return green - done
    if (quantity !== null && soilPersist !== null && stealable !== null && volatile !== null && waterPersist !== null) {
        return '#42CA4E';

    // If some but not all have value, return yellow - pending
    } else if (quantity !== null || soilPersist !== null || stealable !== null || volatile !== null || waterPersist !== null) {
        return '#FFD21F';
    
    // If none of them have a value, return red - none
    } else {
        return '#FF4545';
    }

}

function getStatusMessage(props) {

    const {quantity, soilPersist, stealable, volatile, waterPersist, hasSDS} = props;

    // Check the SDS first
    if(!hasSDS) {
        return 'Missing SDS!';
    }
    // Then check the special values
    else if (quantity === null || soilPersist === null || stealable === null || volatile === null || waterPersist === null) {
        return 'Missing Special Values!';
    
    // If all set, return the OK
    } else {
        return 'Quick Analysis Ready!';
    }

}