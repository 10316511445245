import React, { useState } from 'react';

import { Form, notification, Steps, Typography } from 'antd';
import { getFirestore, doc, setDoc, Timestamp, onSnapshot } from '@firebase/firestore';
import { getAuth, createUserWithEmailAndPassword } from '@firebase/auth';
import { getStorage, ref, uploadBytes } from '@firebase/storage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlask, faIdBadge, faLock } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';

import AuthRegister from './registration/AuthRegister';
import LabRegister from './registration/LabRegister';
import DetailsRegister from './registration/DetailsRegister';
import { setUserDetails } from '../../providers/userSlice';

export default function RegisterForm (props) {
    const { listeners, setListeners } = props;

    // General
    const [currentPage, setCurrentPage] = useState(0);
    const [api, contextHolder] = notification.useNotification();
    const [isLoading, setIsLoading] = useState(false);
    // First Section
    const [signUpForm] = Form.useForm();
    const [details, setDetails] = useState({});
    // Second Section
    const [labCode, setLabCode] = useState('');
    // Third Section
    const [profilePic, setProfilePic] = useState(null);
    // const [userInfo, setUserInfo] = useState({});

    const dispatch = useDispatch();

    const registerUser = async (userInfo) => {
        // Set the component to loading state
        setIsLoading(true);

        try {
            const auth = getAuth();
            const firestore = getFirestore();
            const storage = getStorage();

            // Create the user account
            const {email, password} = details;
            const newUser = await createUserWithEmailAndPassword(auth, email, password);

            // Upload the profile photo
            await uploadBytes(ref(storage, `users/${labCode}/${newUser.user.uid}/pfp.png`), profilePic);

            const newUserDoc = {
                'accessLevel': 1,
                'active': true,
                'assignedLab': labCode,
                'email': email,
                ...userInfo,
                'name_insensitive': userInfo.name.toLowerCase(),
                'verified': false,
                'timeStamp': Timestamp.now()
            };

            // Insert the user record to firestore
            await setDoc(doc(firestore, 'users', newUser.user.uid), newUserDoc);

            // Update the redux store for the user
            dispatch(setUserDetails({...newUserDoc, uid: newUser.user.uid}));

            // Set a listener to update the value if it changes
            const unsub = onSnapshot(doc(firestore, 'users', newUser.user.uid), doc => {
                dispatch(setUserDetails({...doc.data(), uid: doc.id}));
            });

            setListeners(listeners.push(unsub));

            // Feedback to the user
            api.success({
                message: 'Successfully Signed Up',
                description: 'Your account has been created successfully! Please proceed to log in using your credentials.',
                placement: 'topRight'
            });

            // Set the screen back to login
            props.setLogin();

        } catch (err) {

            // Check if email is already in use and display special error message
            if(err.code === 'auth/email-already-in-use') {
                api.error({
                    message: 'Email in use!',
                    description: 'An account already exists for this email address.',
                    placement: 'topRight'
                });
            
            // Otherwise, generic error message 
            } else {
                api.error({
                    message: 'An Error occurred!',
                    description: 'An error occurred while attempting to register the user.',
                    placement: 'topRight'
                });
            }
            
        } finally {
            setIsLoading(false);
        }
    };


    const pages = [
        <AuthRegister signUpForm={signUpForm} setDetails={setDetails} currentPage={currentPage} setCurrentPage={setCurrentPage} />,
        <LabRegister isLoading={isLoading} setIsLoading={setIsLoading} labCode={labCode} setLabCode={setLabCode} currentPage={currentPage} setCurrentPage={setCurrentPage} api={api} />,
        <DetailsRegister isLoading={isLoading} profilePic={profilePic} setProfilePic={setProfilePic} registerUser={registerUser} />,
    ];

    return (
        <div>
            {contextHolder}

            <Steps 
                className="oswald mb-[24px] px-[30px]"
                current={currentPage}
                items={[
                    {
                        title: 'Auth',
                        icon: <FontAwesomeIcon icon={faLock} />,
                        description: <Typography className='text-[12px] font-light'>Email and Password</Typography>
                    },
                    {
                        title: 'Lab',
                        icon: <FontAwesomeIcon icon={faFlask} />,
                        description: <Typography className='text-[12px] font-light'>Select Lab</Typography>
                    },
                    {
                        title: 'Info',
                        icon: <FontAwesomeIcon icon={faIdBadge} />,
                        description: <Typography className='text-[12px] font-light'>Name, Matric, Image</Typography>
                    }
                ]}
            />

            {pages[currentPage]}

            <Typography className='oswald text-center text-[24px] px-[30px]'>
                Already have an account? <br />
                <span className='tertiary cursor-pointer' onClick={props.setLogin}>
                    Login
                </span>
            </Typography>

        </div>
    );
}